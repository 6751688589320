import React, { useState } from "react";
import LayoutV2 from "../layout/LayoutV2";
import LeadMagnetHome from "../lead-magnet/LeadMagnetHome";

export enum AgeRangeType {
  "18_and_above" = "18_and_above",
  "below_18" = "below_18",
}

export type ShareduledUser = {
  firstName: string;
  lastName?: string;
  country: string;
  countryShortCode: string;
  phoneNumber: string;
  phoneNumberCountryName: string;
  phoneNumberCountryShortCode: string;
  businessName?: string;
  businessEmail: string;
  ageRange: AgeRangeType | null;
  businessEmailValid?: boolean;
  phoneNumberValid?: boolean;
};

export default function LeadMagnetScreen() {
  const [currentUser, setCurrentUser] = useState<ShareduledUser>({
    firstName: "",
    lastName: "",
    country: "",
    countryShortCode: "ca",
    phoneNumber: "",
    phoneNumberCountryName: "",
    phoneNumberCountryShortCode: "",
    businessName: "",
    businessEmail: "",
    ageRange: null,
    businessEmailValid: false,
    phoneNumberValid: false,
  });

  return (
    <LayoutV2
      hideNavLinks
      hideFooterNav
      hideFooterResources
      child={
        <React.Fragment>
          <LeadMagnetHome {...currentUser} setUser={setCurrentUser} />
        </React.Fragment>
      }
    />
  );
}
