import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { ROIDataForStepper } from "./screens/ROICalculatorScreen";
import { MarketingUserDto } from "../sdk/open-api";
import { InputAdornment } from "@mui/material";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator=","
        valueIsNumericString
        decimalScale={2}
      />
    );
  }
);

export default function CashFlowForm(
  props: ROIDataForStepper & {
    user: MarketingUserDto | null;
  }
) {
  const {
    averageMonthlyRevenue,
    averageMonthlyExpenses,
    averageMonthlyMarketingSpend,
    setData,
    user,
  } = props;

  const handleFocus = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.target.select();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    switch (name) {
      case "averagemonthlyrevenue":
        setData((prev) => {
          return {
            ...prev,
            averageMonthlyRevenue: Number(value.replaceAll(",", "")),
          };
        });

        break;
      case "averagemonthlyexpenses":
        setData((prev) => {
          return {
            ...prev,
            averageMonthlyExpenses: Number(value.replaceAll(",", "")),
          };
        });

        break;
      case "averagemonthlymarketingspend":
        setData((prev) => {
          return {
            ...prev,
            averageMonthlyMarketingSpend: Number(value.replaceAll(",", "")),
          };
        });
        break;

      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {`Alright, ${user?.first_name ? user?.first_name : ""} Let's Begin.`}
      </Typography>
      <Typography variant="body2" gutterBottom style={{ fontWeight: "200" }}>
        Kindly help us with providing the information below. We'll do the
        Shareduled magic on it.
        <br />
        Let's do this!
      </Typography>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            onFocus={handleFocus}
            id="averagemonthlyrevenue"
            name="averagemonthlyrevenue"
            label="Average Monthly Revenue"
            fullWidth
            autoComplete="average-monthly-revenue"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              inputComponent: NumericFormatCustom as any,
            }}
            helperText="Here, enter your average monthly revenue from services and retail sales. This is the main source of income for your business. You can find this information from your accounting records or reports."
            value={averageMonthlyRevenue ?? ""}
            placeholder="12,000"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            onFocus={handleFocus}
            required
            id="averagemonthlyexpenses"
            name="averagemonthlyexpenses"
            label="Average Monthly Expenses"
            fullWidth
            autoComplete="average-monthly-expenses"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              inputComponent: NumericFormatCustom as any,
            }}
            helperText="Here, please enter your average monthly expenses such as rent, utilities, salaries, supplies, taxes, etc. This is the main source of costs for your business. You can find this information from your accounting records or reports."
            value={averageMonthlyExpenses ?? ""}
            placeholder="5,000"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            onFocus={handleFocus}
            id="averagemonthlymarketingspend"
            name="averagemonthlymarketingspend"
            label="Average Monthly Marketing Spend"
            fullWidth
            autoComplete="average-monthly-marketing-spend"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              inputComponent: NumericFormatCustom as any,
            }}
            helperText="Here, please enter your average monthly marketing spend and average marketing ROI. This is the measure of how much you invest in advertising and promotion and how much revenue you generate from it. You can find this information from your marketing records or reports."
            value={averageMonthlyMarketingSpend ?? ""}
            placeholder="2,000"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
