import { Container, Stack, Typography } from "@mui/material";
import { AppColours } from "../../helpers/colors";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { timeRemainingForLifeTimeAccess } from "../../helpers/miscellaneous";

function MarketingBanner() {
  const navigate = useNavigate();

  const [remainingTime, setRemainingTime] = useState(
    timeRemainingForLifeTimeAccess()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(timeRemainingForLifeTimeAccess());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container
      id="modal-banner"
      maxWidth="xl"
      sx={{
        position: "sticky",
        top: 0,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: AppColours.MainYellow,
        zIndex: 1000,
        mb: { xs: 0, sm: 0, md: 0 },
        pt: { xs: 1, sm: 1, md: 1 },
        pb: { xs: 1, sm: 1, md: 1 },
      }}
    >
      <Stack
        spacing={{ xs: 2, sm: 1 }}
        useFlexGap
        sx={{
          width: { xs: "100%", sm: "100%" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontWeight: "300",
          }}
        >
          Enjoy{" "}
          <Typography
            component="span"
            variant="body2"
            sx={{
              fontWeight: "500",
            }}
          >
            flexible bookings
          </Typography>
          ,{" "}
          <Typography
            component="span"
            variant="body2"
            sx={{
              fontWeight: "500",
            }}
          >
            real-time updates
          </Typography>
          ,{" "}
          <Typography
            component="span"
            variant="body2"
            sx={{
              fontWeight: "500",
            }}
          >
            live availability changes
          </Typography>
          , and{" "}
          <Typography
            component="span"
            variant="body2"
            sx={{
              fontWeight: "500",
            }}
          >
            effortless payment management&nbsp;
          </Typography>{" "}
          for a{" "}
          <Typography
            component="span"
            variant="body2"
            sx={{
              fontWeight: "600",
            }}
          >
            <Link
              component="button"
              onClick={() => {
                navigate("/life-time-access-form");
              }}
            >
              One-Time 500% Savings Today
            </Link>
          </Typography>
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontWeight: "500",
          }}
        >
          Less than 200 slots left
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "600",
            color: AppColours.MainRed,
          }}
        >
          {`${remainingTime?.days.toString().padStart(2, "0")}`} days :{" "}
          {`${remainingTime?.hours.toString().padStart(2, "0")}`} hours :{" "}
          {`${remainingTime?.minutes.toString().padStart(2, "0")}`} minutes :{" "}
          {`${remainingTime?.seconds.toString().padStart(2, "0")}`} seconds to
          go
        </Typography>
      </Stack>
    </Container>
  );
}

export default MarketingBanner;
