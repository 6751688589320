import React from "react";
import { MarketingUserDto } from "../../sdk/open-api";
import { Container, Typography } from "@mui/material";

export default function ComingSoonComponent(props: {
  user: MarketingUserDto | null;
  existing: boolean;
}) {
  const { user } = props;

  return (
    <Container
      maxWidth="lg"
      id="coming-soon-section"
      sx={{
        minHeight: "inherit",
        justifyContent: "center",
        alignItems: "center",
        pt: { xs: 15, sm: 18 },
        pb: { xs: 6, sm: 6 },
      }}
    >
      {user && (
        <React.Fragment>
          <Typography variant="h5" gutterBottom color="primary">
            {`Thank you ${user?.first_name} for showing your interest in Shareduled. We love that you love us.`}
          </Typography>
          <Typography variant="body2">
            {`Shareduled will be coming to your country ${user?.country} soon. We will keep you updated via your email ${user?.business_email}. Do follow us on all our social media platforms to get all our updates in real time!`}
          </Typography>
        </React.Fragment>
      )}
      {!user && (
        <Typography variant="subtitle1" color="primary">
          Hi there! We could not find your user!
        </Typography>
      )}
    </Container>
  );
}
