/* tslint:disable */
/* eslint-disable */
/**
 * Shareduled Main Backend
 * Shareduled API Doc
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddOneRoiCalcResultDto
 */
export interface AddOneRoiCalcResultDto {
    /**
     * 
     * @type {string}
     * @memberof AddOneRoiCalcResultDto
     */
    'marketing_user_uuid': string;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'initial_average_monthly_revenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'initial_average_monthly_expenses'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'initial_average_monthly_marketing_spend'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'projected_average_monthly_revenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'projected_average_monthly_expenses'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'projected_average_monthly_marketing_spend'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'initial_average_monthly_bookings'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'initial_average_monthly_no_shows'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'projected_average_monthly_bookings'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'projected_average_monthly_no_shows'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'initial_roi'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'projected_roi'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'initial_profit_margin'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOneRoiCalcResultDto
     */
    'projected_profit_margin'?: number;
}
/**
 * 
 * @export
 * @interface BaseServiceResponse
 */
export interface BaseServiceResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BaseServiceResponse
     */
    'success': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseServiceResponse
     */
    'existing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseServiceResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface BusinessAddressEntity
 */
export interface BusinessAddressEntity {
    /**
     * 
     * @type {string}
     * @memberof BusinessAddressEntity
     */
    'business_uuid': string;
    /**
     * 
     * @type {BusinessEntity}
     * @memberof BusinessAddressEntity
     */
    'business': BusinessEntity;
    /**
     * 
     * @type {string}
     * @memberof BusinessAddressEntity
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessAddressEntity
     */
    'sub_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessAddressEntity
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessAddressEntity
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessAddressEntity
     */
    'state_short'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessAddressEntity
     */
    'postal_code': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessAddressEntity
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessAddressEntity
     */
    'country_short': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessAddressEntity
     */
    'place_id': string;
    /**
     * 
     * @type {number}
     * @memberof BusinessAddressEntity
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof BusinessAddressEntity
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof BusinessAddressEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BusinessAddressEntity
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessAddressEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessAddressEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface BusinessEntity
 */
export interface BusinessEntity {
    /**
     * 
     * @type {string}
     * @memberof BusinessEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessEntity
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessEntity
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessEntity
     */
    'phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessEntity
     */
    'business_type'?: BusinessEntityBusinessTypeEnum;
    /**
     * 
     * @type {Array<BusinessAddressEntity>}
     * @memberof BusinessEntity
     */
    'addresses': Array<BusinessAddressEntity>;
    /**
     * 
     * @type {number}
     * @memberof BusinessEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BusinessEntity
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessEntity
     */
    'updated_at': string;
}

export const BusinessEntityBusinessTypeEnum = {
    MassageHouse: 'massage_house',
    Salon: 'salon',
    Spa: 'spa',
    BarberShop: 'barber_shop',
    Others: 'others'
} as const;

export type BusinessEntityBusinessTypeEnum = typeof BusinessEntityBusinessTypeEnum[keyof typeof BusinessEntityBusinessTypeEnum];

/**
 * 
 * @export
 * @interface BusinessLoginDto
 */
export interface BusinessLoginDto {
    /**
     * 
     * @type {string}
     * @memberof BusinessLoginDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessLoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface BusinessLoginResponseDto
 */
export interface BusinessLoginResponseDto {
    /**
     * 
     * @type {BusinessEntity}
     * @memberof BusinessLoginResponseDto
     */
    'data': BusinessEntity;
    /**
     * 
     * @type {string}
     * @memberof BusinessLoginResponseDto
     */
    'accessToken': string;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessLoginResponseDto
     */
    'success': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessLoginResponseDto
     */
    'existing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BusinessLoginResponseDto
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface BusinessOneResponseDto
 */
export interface BusinessOneResponseDto {
    /**
     * 
     * @type {BusinessEntity}
     * @memberof BusinessOneResponseDto
     */
    'data': BusinessEntity | null;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessOneResponseDto
     */
    'success': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessOneResponseDto
     */
    'existing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BusinessOneResponseDto
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface FetchWaitlistUserDto
 */
export interface FetchWaitlistUserDto {
    /**
     * 
     * @type {string}
     * @memberof FetchWaitlistUserDto
     */
    'uuid': string;
}
/**
 * 
 * @export
 * @interface ForgotPasswordCodeInputDto
 */
export interface ForgotPasswordCodeInputDto {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordCodeInputDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface MarketingUserDto
 */
export interface MarketingUserDto {
    /**
     * 
     * @type {string}
     * @memberof MarketingUserDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserDto
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserDto
     */
    'phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserDto
     */
    'business_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserDto
     */
    'business_email': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserDto
     */
    'age_range': MarketingUserDtoAgeRangeEnum;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserDto
     */
    'user_path': MarketingUserDtoUserPathEnum;
}

export const MarketingUserDtoAgeRangeEnum = {
    _18AndAbove: '18_and_above',
    Below18: 'below_18',
    NotProvided: 'not_provided'
} as const;

export type MarketingUserDtoAgeRangeEnum = typeof MarketingUserDtoAgeRangeEnum[keyof typeof MarketingUserDtoAgeRangeEnum];
export const MarketingUserDtoUserPathEnum = {
    Waitlist: 'waitlist',
    LifeTimeAccess: 'life-time-access',
    RoiCalculator: 'roi-calculator'
} as const;

export type MarketingUserDtoUserPathEnum = typeof MarketingUserDtoUserPathEnum[keyof typeof MarketingUserDtoUserPathEnum];

/**
 * 
 * @export
 * @interface MarketingUserEntity
 */
export interface MarketingUserEntity {
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'business_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'business_email': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'payment_status'?: MarketingUserEntityPaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'age_range'?: MarketingUserEntityAgeRangeEnum;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'category_tag'?: MarketingUserEntityCategoryTagEnum;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'payment_platform'?: MarketingUserEntityPaymentPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'life_time_access_code'?: string;
    /**
     * 
     * @type {Array<RoiCalculatorResultsEntity>}
     * @memberof MarketingUserEntity
     */
    'roi_calculator_results': Array<RoiCalculatorResultsEntity>;
    /**
     * 
     * @type {number}
     * @memberof MarketingUserEntity
     */
    'birdsend_contact_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarketingUserEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserEntity
     */
    'updated_at': string;
}

export const MarketingUserEntityPaymentStatusEnum = {
    Pending: 'pending',
    Success: 'success',
    HasIssue: 'has_issue'
} as const;

export type MarketingUserEntityPaymentStatusEnum = typeof MarketingUserEntityPaymentStatusEnum[keyof typeof MarketingUserEntityPaymentStatusEnum];
export const MarketingUserEntityAgeRangeEnum = {
    _18AndAbove: '18_and_above',
    Below18: 'below_18',
    NotProvided: 'not_provided'
} as const;

export type MarketingUserEntityAgeRangeEnum = typeof MarketingUserEntityAgeRangeEnum[keyof typeof MarketingUserEntityAgeRangeEnum];
export const MarketingUserEntityCategoryTagEnum = {
    SharLifeTimeAccess: 'SHAR_LIFE_TIME_ACCESS'
} as const;

export type MarketingUserEntityCategoryTagEnum = typeof MarketingUserEntityCategoryTagEnum[keyof typeof MarketingUserEntityCategoryTagEnum];
export const MarketingUserEntityPaymentPlatformEnum = {
    Stripe: 'STRIPE',
    Paypal: 'PAYPAL'
} as const;

export type MarketingUserEntityPaymentPlatformEnum = typeof MarketingUserEntityPaymentPlatformEnum[keyof typeof MarketingUserEntityPaymentPlatformEnum];

/**
 * 
 * @export
 * @interface MarketingUserOneRespDto
 */
export interface MarketingUserOneRespDto {
    /**
     * 
     * @type {MarketingUserEntity}
     * @memberof MarketingUserOneRespDto
     */
    'data': MarketingUserEntity | null;
    /**
     * 
     * @type {boolean}
     * @memberof MarketingUserOneRespDto
     */
    'success': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MarketingUserOneRespDto
     */
    'existing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MarketingUserOneRespDto
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface PaypalCaptureOrderInputDto
 */
export interface PaypalCaptureOrderInputDto {
    /**
     * 
     * @type {string}
     * @memberof PaypalCaptureOrderInputDto
     */
    'orderID': string;
}
/**
 * 
 * @export
 * @interface PaypalCaptureOrderRespDataDto
 */
export interface PaypalCaptureOrderRespDataDto {
    /**
     * 
     * @type {string}
     * @memberof PaypalCaptureOrderRespDataDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalCaptureOrderRespDataDto
     */
    'status': string;
    /**
     * 
     * @type {PaypalCaptureOrderRespDataDtoPaymentSource}
     * @memberof PaypalCaptureOrderRespDataDto
     */
    'payment_source': PaypalCaptureOrderRespDataDtoPaymentSource;
    /**
     * 
     * @type {Array<PaypalPurchaseUnit>}
     * @memberof PaypalCaptureOrderRespDataDto
     */
    'purchase_units': Array<PaypalPurchaseUnit>;
    /**
     * 
     * @type {PaypalCaptureOrderRespDataDtoPayer}
     * @memberof PaypalCaptureOrderRespDataDto
     */
    'payer': PaypalCaptureOrderRespDataDtoPayer;
    /**
     * 
     * @type {Array<PaypalLink>}
     * @memberof PaypalCaptureOrderRespDataDto
     */
    'links': Array<PaypalLink>;
}
/**
 * 
 * @export
 * @interface PaypalCaptureOrderRespDataDtoPayer
 */
export interface PaypalCaptureOrderRespDataDtoPayer {
    /**
     * 
     * @type {PaypalCaptureOrderRespDataDtoPaymentSourcePaypalName}
     * @memberof PaypalCaptureOrderRespDataDtoPayer
     */
    'name'?: PaypalCaptureOrderRespDataDtoPaymentSourcePaypalName;
    /**
     * 
     * @type {string}
     * @memberof PaypalCaptureOrderRespDataDtoPayer
     */
    'email_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaypalCaptureOrderRespDataDtoPayer
     */
    'payer_id'?: string;
    /**
     * 
     * @type {PaypalCaptureOrderRespDataDtoPayerAddress}
     * @memberof PaypalCaptureOrderRespDataDtoPayer
     */
    'address'?: PaypalCaptureOrderRespDataDtoPayerAddress;
}
/**
 * 
 * @export
 * @interface PaypalCaptureOrderRespDataDtoPayerAddress
 */
export interface PaypalCaptureOrderRespDataDtoPayerAddress {
    /**
     * 
     * @type {string}
     * @memberof PaypalCaptureOrderRespDataDtoPayerAddress
     */
    'country_code'?: string;
}
/**
 * 
 * @export
 * @interface PaypalCaptureOrderRespDataDtoPaymentSource
 */
export interface PaypalCaptureOrderRespDataDtoPaymentSource {
    /**
     * 
     * @type {PaypalCaptureOrderRespDataDtoPaymentSourcePaypal}
     * @memberof PaypalCaptureOrderRespDataDtoPaymentSource
     */
    'paypal'?: PaypalCaptureOrderRespDataDtoPaymentSourcePaypal;
}
/**
 * 
 * @export
 * @interface PaypalCaptureOrderRespDataDtoPaymentSourcePaypal
 */
export interface PaypalCaptureOrderRespDataDtoPaymentSourcePaypal {
    /**
     * 
     * @type {PaypalCaptureOrderRespDataDtoPaymentSourcePaypalName}
     * @memberof PaypalCaptureOrderRespDataDtoPaymentSourcePaypal
     */
    'name'?: PaypalCaptureOrderRespDataDtoPaymentSourcePaypalName;
    /**
     * 
     * @type {string}
     * @memberof PaypalCaptureOrderRespDataDtoPaymentSourcePaypal
     */
    'email_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaypalCaptureOrderRespDataDtoPaymentSourcePaypal
     */
    'account_id'?: string;
}
/**
 * 
 * @export
 * @interface PaypalCaptureOrderRespDataDtoPaymentSourcePaypalName
 */
export interface PaypalCaptureOrderRespDataDtoPaymentSourcePaypalName {
    /**
     * 
     * @type {string}
     * @memberof PaypalCaptureOrderRespDataDtoPaymentSourcePaypalName
     */
    'given_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaypalCaptureOrderRespDataDtoPaymentSourcePaypalName
     */
    'surname'?: string;
}
/**
 * 
 * @export
 * @interface PaypalCaptureOrderRespDto
 */
export interface PaypalCaptureOrderRespDto {
    /**
     * 
     * @type {PaypalCaptureOrderRespDataDto}
     * @memberof PaypalCaptureOrderRespDto
     */
    'data'?: PaypalCaptureOrderRespDataDto;
    /**
     * 
     * @type {number}
     * @memberof PaypalCaptureOrderRespDto
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof PaypalCaptureOrderRespDto
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalCaptureOrderRespDto
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface PaypalCreateOrderRespDataDto
 */
export interface PaypalCreateOrderRespDataDto {
    /**
     * 
     * @type {string}
     * @memberof PaypalCreateOrderRespDataDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalCreateOrderRespDataDto
     */
    'status': string;
    /**
     * 
     * @type {Array<PaypalLink>}
     * @memberof PaypalCreateOrderRespDataDto
     */
    'links': Array<PaypalLink>;
}
/**
 * 
 * @export
 * @interface PaypalCreateOrderRespDto
 */
export interface PaypalCreateOrderRespDto {
    /**
     * 
     * @type {PaypalCreateOrderRespDataDto}
     * @memberof PaypalCreateOrderRespDto
     */
    'data'?: PaypalCreateOrderRespDataDto;
    /**
     * 
     * @type {number}
     * @memberof PaypalCreateOrderRespDto
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof PaypalCreateOrderRespDto
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalCreateOrderRespDto
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface PaypalLink
 */
export interface PaypalLink {
    /**
     * 
     * @type {string}
     * @memberof PaypalLink
     */
    'href': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalLink
     */
    'rel': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalLink
     */
    'method': string;
}
/**
 * 
 * @export
 * @interface PaypalPaymentsCapture
 */
export interface PaypalPaymentsCapture {
    /**
     * 
     * @type {string}
     * @memberof PaypalPaymentsCapture
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalPaymentsCapture
     */
    'status': string;
    /**
     * 
     * @type {PaypalPaymentsCaptureAmount}
     * @memberof PaypalPaymentsCapture
     */
    'amount': PaypalPaymentsCaptureAmount;
    /**
     * 
     * @type {PaypalPaymentsCaptureSellerProtection}
     * @memberof PaypalPaymentsCapture
     */
    'seller_protection': PaypalPaymentsCaptureSellerProtection;
    /**
     * 
     * @type {boolean}
     * @memberof PaypalPaymentsCapture
     */
    'final_capture': boolean;
    /**
     * 
     * @type {string}
     * @memberof PaypalPaymentsCapture
     */
    'disbursement_mode': string;
    /**
     * 
     * @type {PaypalPaymentsCaptureSellerReceivableBreakdown}
     * @memberof PaypalPaymentsCapture
     */
    'seller_receivable_breakdown': PaypalPaymentsCaptureSellerReceivableBreakdown;
    /**
     * 
     * @type {string}
     * @memberof PaypalPaymentsCapture
     */
    'create_time': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalPaymentsCapture
     */
    'update_time': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalPaymentsCapture
     */
    'custom_id': string;
    /**
     * 
     * @type {Array<PaypalLink>}
     * @memberof PaypalPaymentsCapture
     */
    'links': Array<PaypalLink>;
}
/**
 * 
 * @export
 * @interface PaypalPaymentsCaptureAmount
 */
export interface PaypalPaymentsCaptureAmount {
    /**
     * 
     * @type {string}
     * @memberof PaypalPaymentsCaptureAmount
     */
    'currency_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaypalPaymentsCaptureAmount
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface PaypalPaymentsCaptureSellerProtection
 */
export interface PaypalPaymentsCaptureSellerProtection {
    /**
     * 
     * @type {string}
     * @memberof PaypalPaymentsCaptureSellerProtection
     */
    'status'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaypalPaymentsCaptureSellerProtection
     */
    'dispute_categories'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PaypalPaymentsCaptureSellerReceivableBreakdown
 */
export interface PaypalPaymentsCaptureSellerReceivableBreakdown {
    /**
     * 
     * @type {PaypalPaymentsCaptureAmount}
     * @memberof PaypalPaymentsCaptureSellerReceivableBreakdown
     */
    'gross_amount'?: PaypalPaymentsCaptureAmount;
    /**
     * 
     * @type {PaypalPaymentsCaptureAmount}
     * @memberof PaypalPaymentsCaptureSellerReceivableBreakdown
     */
    'paypal_fee'?: PaypalPaymentsCaptureAmount;
    /**
     * 
     * @type {PaypalPaymentsCaptureAmount}
     * @memberof PaypalPaymentsCaptureSellerReceivableBreakdown
     */
    'net_amount'?: PaypalPaymentsCaptureAmount;
}
/**
 * 
 * @export
 * @interface PaypalPurchaseUnit
 */
export interface PaypalPurchaseUnit {
    /**
     * 
     * @type {string}
     * @memberof PaypalPurchaseUnit
     */
    'reference_id': string;
    /**
     * 
     * @type {PaypalPaymentsCaptureAmount}
     * @memberof PaypalPurchaseUnit
     */
    'amount': PaypalPaymentsCaptureAmount;
    /**
     * 
     * @type {PaypalPurchaseUnitPayee}
     * @memberof PaypalPurchaseUnit
     */
    'payee': PaypalPurchaseUnitPayee;
    /**
     * 
     * @type {string}
     * @memberof PaypalPurchaseUnit
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalPurchaseUnit
     */
    'custom_id': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalPurchaseUnit
     */
    'soft_descriptor': string;
    /**
     * 
     * @type {PaypalPurchaseUnitShipping}
     * @memberof PaypalPurchaseUnit
     */
    'shipping': PaypalPurchaseUnitShipping;
    /**
     * 
     * @type {PaypalPurchaseUnitPayments}
     * @memberof PaypalPurchaseUnit
     */
    'payments': PaypalPurchaseUnitPayments;
}
/**
 * 
 * @export
 * @interface PaypalPurchaseUnitPayee
 */
export interface PaypalPurchaseUnitPayee {
    /**
     * 
     * @type {string}
     * @memberof PaypalPurchaseUnitPayee
     */
    'email_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaypalPurchaseUnitPayee
     */
    'merchant_id'?: string;
}
/**
 * 
 * @export
 * @interface PaypalPurchaseUnitPayments
 */
export interface PaypalPurchaseUnitPayments {
    /**
     * 
     * @type {Array<PaypalPaymentsCapture>}
     * @memberof PaypalPurchaseUnitPayments
     */
    'captures'?: Array<PaypalPaymentsCapture>;
}
/**
 * 
 * @export
 * @interface PaypalPurchaseUnitShipping
 */
export interface PaypalPurchaseUnitShipping {
    /**
     * 
     * @type {PaypalPurchaseUnitShippingName}
     * @memberof PaypalPurchaseUnitShipping
     */
    'name'?: PaypalPurchaseUnitShippingName;
    /**
     * 
     * @type {PaypalPurchaseUnitShippingAddress}
     * @memberof PaypalPurchaseUnitShipping
     */
    'address'?: PaypalPurchaseUnitShippingAddress;
}
/**
 * 
 * @export
 * @interface PaypalPurchaseUnitShippingAddress
 */
export interface PaypalPurchaseUnitShippingAddress {
    /**
     * 
     * @type {string}
     * @memberof PaypalPurchaseUnitShippingAddress
     */
    'address_line_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaypalPurchaseUnitShippingAddress
     */
    'admin_area_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaypalPurchaseUnitShippingAddress
     */
    'admin_area_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaypalPurchaseUnitShippingAddress
     */
    'postal_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaypalPurchaseUnitShippingAddress
     */
    'country_code'?: string;
}
/**
 * 
 * @export
 * @interface PaypalPurchaseUnitShippingName
 */
export interface PaypalPurchaseUnitShippingName {
    /**
     * 
     * @type {string}
     * @memberof PaypalPurchaseUnitShippingName
     */
    'full_name'?: string;
}
/**
 * 
 * @export
 * @interface PaypalWebhookDto
 */
export interface PaypalWebhookDto {
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDto
     */
    'event_version': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDto
     */
    'create_time': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDto
     */
    'resource_type': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDto
     */
    'resource_version': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDto
     */
    'event_type': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDto
     */
    'summary': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDto
     */
    'intent': string;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDto
     */
    'status': string;
    /**
     * 
     * @type {PaypalWebhookDtoResource}
     * @memberof PaypalWebhookDto
     */
    'resource': PaypalWebhookDtoResource;
    /**
     * 
     * @type {Array<PaypalLink>}
     * @memberof PaypalWebhookDto
     */
    'links': Array<PaypalLink>;
}
/**
 * 
 * @export
 * @interface PaypalWebhookDtoResource
 */
export interface PaypalWebhookDtoResource {
    /**
     * 
     * @type {PaypalPurchaseUnitPayee}
     * @memberof PaypalWebhookDtoResource
     */
    'payee'?: PaypalPurchaseUnitPayee;
    /**
     * 
     * @type {PaypalWebhookDtoResourceAmount}
     * @memberof PaypalWebhookDtoResource
     */
    'amount'?: PaypalWebhookDtoResourceAmount;
    /**
     * 
     * @type {PaypalWebhookDtoResourceSellerProtection}
     * @memberof PaypalWebhookDtoResource
     */
    'seller_protection'?: PaypalWebhookDtoResourceSellerProtection;
    /**
     * 
     * @type {PaypalWebhookDtoResourceSupplementaryData}
     * @memberof PaypalWebhookDtoResource
     */
    'supplementary_data'?: PaypalWebhookDtoResourceSupplementaryData;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDtoResource
     */
    'create_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDtoResource
     */
    'update_time'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaypalWebhookDtoResource
     */
    'final_capture'?: boolean;
    /**
     * 
     * @type {PaypalWebhookDtoResourceSellerReceivableBreakdown}
     * @memberof PaypalWebhookDtoResource
     */
    'seller_receivable_breakdown'?: PaypalWebhookDtoResourceSellerReceivableBreakdown;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDtoResource
     */
    'custom_id'?: string;
    /**
     * 
     * @type {Array<PaypalLink>}
     * @memberof PaypalWebhookDtoResource
     */
    'links'?: Array<PaypalLink>;
}
/**
 * 
 * @export
 * @interface PaypalWebhookDtoResourceAmount
 */
export interface PaypalWebhookDtoResourceAmount {
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDtoResourceAmount
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDtoResourceAmount
     */
    'currency_code'?: string;
}
/**
 * 
 * @export
 * @interface PaypalWebhookDtoResourceSellerProtection
 */
export interface PaypalWebhookDtoResourceSellerProtection {
    /**
     * 
     * @type {Array<string>}
     * @memberof PaypalWebhookDtoResourceSellerProtection
     */
    'dispute_categories'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDtoResourceSellerProtection
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface PaypalWebhookDtoResourceSellerReceivableBreakdown
 */
export interface PaypalWebhookDtoResourceSellerReceivableBreakdown {
    /**
     * 
     * @type {PaypalWebhookDtoResourceSellerReceivableBreakdownExchangeRate}
     * @memberof PaypalWebhookDtoResourceSellerReceivableBreakdown
     */
    'exchange_rate'?: PaypalWebhookDtoResourceSellerReceivableBreakdownExchangeRate;
    /**
     * 
     * @type {PaypalWebhookDtoResourceAmount}
     * @memberof PaypalWebhookDtoResourceSellerReceivableBreakdown
     */
    'paypal_fee'?: PaypalWebhookDtoResourceAmount;
    /**
     * 
     * @type {PaypalWebhookDtoResourceAmount}
     * @memberof PaypalWebhookDtoResourceSellerReceivableBreakdown
     */
    'gross_amount'?: PaypalWebhookDtoResourceAmount;
    /**
     * 
     * @type {PaypalWebhookDtoResourceAmount}
     * @memberof PaypalWebhookDtoResourceSellerReceivableBreakdown
     */
    'net_amount'?: PaypalWebhookDtoResourceAmount;
    /**
     * 
     * @type {PaypalWebhookDtoResourceAmount}
     * @memberof PaypalWebhookDtoResourceSellerReceivableBreakdown
     */
    'receivable_amount'?: PaypalWebhookDtoResourceAmount;
}
/**
 * 
 * @export
 * @interface PaypalWebhookDtoResourceSellerReceivableBreakdownExchangeRate
 */
export interface PaypalWebhookDtoResourceSellerReceivableBreakdownExchangeRate {
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDtoResourceSellerReceivableBreakdownExchangeRate
     */
    'source_currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDtoResourceSellerReceivableBreakdownExchangeRate
     */
    'target_currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDtoResourceSellerReceivableBreakdownExchangeRate
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface PaypalWebhookDtoResourceSupplementaryData
 */
export interface PaypalWebhookDtoResourceSupplementaryData {
    /**
     * 
     * @type {PaypalWebhookDtoResourceSupplementaryDataRelatedIds}
     * @memberof PaypalWebhookDtoResourceSupplementaryData
     */
    'related_ids'?: PaypalWebhookDtoResourceSupplementaryDataRelatedIds;
}
/**
 * 
 * @export
 * @interface PaypalWebhookDtoResourceSupplementaryDataRelatedIds
 */
export interface PaypalWebhookDtoResourceSupplementaryDataRelatedIds {
    /**
     * 
     * @type {string}
     * @memberof PaypalWebhookDtoResourceSupplementaryDataRelatedIds
     */
    'order_id'?: string;
}
/**
 * 
 * @export
 * @interface RegisterBusinessDto
 */
export interface RegisterBusinessDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterBusinessDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBusinessDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBusinessDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBusinessDto
     */
    'phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBusinessDto
     */
    'business_type'?: RegisterBusinessDtoBusinessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RegisterBusinessDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBusinessDto
     */
    'sub_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBusinessDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBusinessDto
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBusinessDto
     */
    'state_short'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBusinessDto
     */
    'postal_code': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBusinessDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBusinessDto
     */
    'country_short': string;
    /**
     * 
     * @type {number}
     * @memberof RegisterBusinessDto
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof RegisterBusinessDto
     */
    'latitude': number;
}

export const RegisterBusinessDtoBusinessTypeEnum = {
    MassageHouse: 'massage_house',
    Salon: 'salon',
    Spa: 'spa',
    BarberShop: 'barber_shop',
    Others: 'others'
} as const;

export type RegisterBusinessDtoBusinessTypeEnum = typeof RegisterBusinessDtoBusinessTypeEnum[keyof typeof RegisterBusinessDtoBusinessTypeEnum];

/**
 * 
 * @export
 * @interface RoiCalcRespDto
 */
export interface RoiCalcRespDto {
    /**
     * 
     * @type {RoiCalculatorResultsEntity}
     * @memberof RoiCalcRespDto
     */
    'data': RoiCalculatorResultsEntity | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoiCalcRespDto
     */
    'success': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoiCalcRespDto
     */
    'existing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoiCalcRespDto
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface RoiCalculatorResultsEntity
 */
export interface RoiCalculatorResultsEntity {
    /**
     * 
     * @type {string}
     * @memberof RoiCalculatorResultsEntity
     */
    'marketing_user_uuid': string;
    /**
     * 
     * @type {MarketingUserEntity}
     * @memberof RoiCalculatorResultsEntity
     */
    'marketing_user': MarketingUserEntity;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'initial_average_monthly_revenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'initial_average_monthly_expenses'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'initial_average_monthly_marketing_spend'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'projected_average_monthly_revenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'projected_average_monthly_expenses'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'projected_average_monthly_marketing_spend'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'initial_average_monthly_bookings'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'initial_average_monthly_no_shows'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'projected_average_monthly_bookings'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'projected_average_monthly_no_shows'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'initial_roi'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'projected_roi'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'initial_profit_margin'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'projected_profit_margin'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoiCalculatorResultsEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RoiCalculatorResultsEntity
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof RoiCalculatorResultsEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof RoiCalculatorResultsEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface ShareduledUserEntity
 */
export interface ShareduledUserEntity {
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserEntity
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserEntity
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserEntity
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserEntity
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserEntity
     */
    'receive_new_appointment_notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserEntity
     */
    'receive_appointment_reminder_notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserEntity
     */
    'receive_appointment_reschedule_notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserEntity
     */
    'receive_appointment_completion_notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserEntity
     */
    'receive_appointment_cancellation_notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserEntity
     */
    'receive_settings_change_notification'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserEntity
     */
    'forgot_password_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShareduledUserEntity
     */
    'forgot_password_code_expiration'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShareduledUserEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserEntity
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserEntity
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserEntity
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface ShareduledUserEntityDto
 */
export interface ShareduledUserEntityDto {
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserEntityDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserEntityDto
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserEntityDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserEntityDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ShareduledUserLoginDto
 */
export interface ShareduledUserLoginDto {
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserLoginDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserLoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ShareduledUserOneRespDto
 */
export interface ShareduledUserOneRespDto {
    /**
     * 
     * @type {ShareduledUserEntity}
     * @memberof ShareduledUserOneRespDto
     */
    'data': ShareduledUserEntity | null;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserOneRespDto
     */
    'accessToken': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserOneRespDto
     */
    'success': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserOneRespDto
     */
    'existing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserOneRespDto
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ShareduledUserUpdatePasswordDto
 */
export interface ShareduledUserUpdatePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserUpdatePasswordDto
     */
    'oldPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserUpdatePasswordDto
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface ShareduledUserUpdateProfileDto
 */
export interface ShareduledUserUpdateProfileDto {
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserUpdateProfileDto
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserUpdateProfileDto
     */
    'last_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserUpdateProfileDto
     */
    'receive_new_appointment_notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserUpdateProfileDto
     */
    'receive_appointment_reminder_notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserUpdateProfileDto
     */
    'receive_appointment_reschedule_notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserUpdateProfileDto
     */
    'receive_appointment_completion_notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserUpdateProfileDto
     */
    'receive_appointment_cancellation_notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserUpdateProfileDto
     */
    'receive_settings_change_notification'?: boolean;
}
/**
 * 
 * @export
 * @interface ShareduledUserUpdateProfileRespDto
 */
export interface ShareduledUserUpdateProfileRespDto {
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserUpdateProfileRespDto
     */
    'success': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShareduledUserUpdateProfileRespDto
     */
    'existing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShareduledUserUpdateProfileRespDto
     */
    'message'?: string;
    /**
     * 
     * @type {ShareduledUserEntity}
     * @memberof ShareduledUserUpdateProfileRespDto
     */
    'data': ShareduledUserEntity | null;
}
/**
 * 
 * @export
 * @interface StripePaymentIntentResponseDto
 */
export interface StripePaymentIntentResponseDto {
    /**
     * 
     * @type {string}
     * @memberof StripePaymentIntentResponseDto
     */
    'clientSecret'?: string;
    /**
     * 
     * @type {number}
     * @memberof StripePaymentIntentResponseDto
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof StripePaymentIntentResponseDto
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof StripePaymentIntentResponseDto
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface VerifyEmailInputDto
 */
export interface VerifyEmailInputDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailInputDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface VerifyForgotPasswordCodeInputDto
 */
export interface VerifyForgotPasswordCodeInputDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyForgotPasswordCodeInputDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyForgotPasswordCodeInputDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface VerifyPhoneInputDto
 */
export interface VerifyPhoneInputDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyPhoneInputDto
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface VerifyResponseDto
 */
export interface VerifyResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof VerifyResponseDto
     */
    'isValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VerifyResponseDto
     */
    'hasServerError': boolean;
    /**
     * 
     * @type {number}
     * @memberof VerifyResponseDto
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof VerifyResponseDto
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyResponseDto
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface WebhookResponseDto
 */
export interface WebhookResponseDto {
    /**
     * 
     * @type {string}
     * @memberof WebhookResponseDto
     */
    'message': string;
}

/**
 * BusinessOwnerApi - axios parameter creator
 * @export
 */
export const BusinessOwnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessControllerGetProfile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/business/owner/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BusinessLoginDto} businessLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessControllerLogin: async (businessLoginDto: BusinessLoginDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessLoginDto' is not null or undefined
            assertParamExists('businessControllerLogin', 'businessLoginDto', businessLoginDto)
            const localVarPath = `/business/owner/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessLoginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterBusinessDto} registerBusinessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessControllerRegister: async (registerBusinessDto: RegisterBusinessDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerBusinessDto' is not null or undefined
            assertParamExists('businessControllerRegister', 'registerBusinessDto', registerBusinessDto)
            const localVarPath = `/business/owner/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerBusinessDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessOwnerApi - functional programming interface
 * @export
 */
export const BusinessOwnerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessOwnerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessControllerGetProfile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessControllerGetProfile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessOwnerApi.businessControllerGetProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {BusinessLoginDto} businessLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessControllerLogin(businessLoginDto: BusinessLoginDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessLoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessControllerLogin(businessLoginDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessOwnerApi.businessControllerLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RegisterBusinessDto} registerBusinessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessControllerRegister(registerBusinessDto: RegisterBusinessDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessOneResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessControllerRegister(registerBusinessDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BusinessOwnerApi.businessControllerRegister']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BusinessOwnerApi - factory interface
 * @export
 */
export const BusinessOwnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessOwnerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessControllerGetProfile(options?: any): AxiosPromise<BusinessEntity> {
            return localVarFp.businessControllerGetProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BusinessLoginDto} businessLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessControllerLogin(businessLoginDto: BusinessLoginDto, options?: any): AxiosPromise<BusinessLoginResponseDto> {
            return localVarFp.businessControllerLogin(businessLoginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterBusinessDto} registerBusinessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessControllerRegister(registerBusinessDto: RegisterBusinessDto, options?: any): AxiosPromise<BusinessOneResponseDto> {
            return localVarFp.businessControllerRegister(registerBusinessDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessOwnerApi - interface
 * @export
 * @interface BusinessOwnerApi
 */
export interface BusinessOwnerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessOwnerApiInterface
     */
    businessControllerGetProfile(options?: RawAxiosRequestConfig): AxiosPromise<BusinessEntity>;

    /**
     * 
     * @param {BusinessLoginDto} businessLoginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessOwnerApiInterface
     */
    businessControllerLogin(businessLoginDto: BusinessLoginDto, options?: RawAxiosRequestConfig): AxiosPromise<BusinessLoginResponseDto>;

    /**
     * 
     * @param {RegisterBusinessDto} registerBusinessDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessOwnerApiInterface
     */
    businessControllerRegister(registerBusinessDto: RegisterBusinessDto, options?: RawAxiosRequestConfig): AxiosPromise<BusinessOneResponseDto>;

}

/**
 * BusinessOwnerApi - object-oriented interface
 * @export
 * @class BusinessOwnerApi
 * @extends {BaseAPI}
 */
export class BusinessOwnerApi extends BaseAPI implements BusinessOwnerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessOwnerApi
     */
    public businessControllerGetProfile(options?: RawAxiosRequestConfig) {
        return BusinessOwnerApiFp(this.configuration).businessControllerGetProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BusinessLoginDto} businessLoginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessOwnerApi
     */
    public businessControllerLogin(businessLoginDto: BusinessLoginDto, options?: RawAxiosRequestConfig) {
        return BusinessOwnerApiFp(this.configuration).businessControllerLogin(businessLoginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterBusinessDto} registerBusinessDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessOwnerApi
     */
    public businessControllerRegister(registerBusinessDto: RegisterBusinessDto, options?: RawAxiosRequestConfig) {
        return BusinessOwnerApiFp(this.configuration).businessControllerRegister(registerBusinessDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHello(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.appControllerGetHello']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello(options?: any): AxiosPromise<string> {
            return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    appControllerGetHello(options?: RawAxiosRequestConfig): AxiosPromise<string>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHello(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerGetHello(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PaypalCaptureOrderInputDto} paypalCaptureOrderInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerCaptureLifeTimeAccessOrder: async (paypalCaptureOrderInputDto: PaypalCaptureOrderInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paypalCaptureOrderInputDto' is not null or undefined
            assertParamExists('publicControllerCaptureLifeTimeAccessOrder', 'paypalCaptureOrderInputDto', paypalCaptureOrderInputDto)
            const localVarPath = `/public/capture-life-time-access-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paypalCaptureOrderInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MarketingUserDto} marketingUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerCreateJoinforLifePaymentIntent: async (marketingUserDto: MarketingUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketingUserDto' is not null or undefined
            assertParamExists('publicControllerCreateJoinforLifePaymentIntent', 'marketingUserDto', marketingUserDto)
            const localVarPath = `/public/life-time-access-payment-intent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketingUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MarketingUserDto} marketingUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerCreateLifeTimeAccessOrder: async (marketingUserDto: MarketingUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketingUserDto' is not null or undefined
            assertParamExists('publicControllerCreateLifeTimeAccessOrder', 'marketingUserDto', marketingUserDto)
            const localVarPath = `/public/create-life-time-access-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketingUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MarketingUserDto} marketingUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerCreateMarketingUser: async (marketingUserDto: MarketingUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketingUserDto' is not null or undefined
            assertParamExists('publicControllerCreateMarketingUser', 'marketingUserDto', marketingUserDto)
            const localVarPath = `/public/create-marketing-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketingUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FetchWaitlistUserDto} fetchWaitlistUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetOneWaitlistUser: async (fetchWaitlistUserDto: FetchWaitlistUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fetchWaitlistUserDto' is not null or undefined
            assertParamExists('publicControllerGetOneWaitlistUser', 'fetchWaitlistUserDto', fetchWaitlistUserDto)
            const localVarPath = `/public/fetch-waitlist-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchWaitlistUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaypalWebhookDto} paypalWebhookDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPaypalWebhook: async (paypalWebhookDto: PaypalWebhookDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paypalWebhookDto' is not null or undefined
            assertParamExists('publicControllerPaypalWebhook', 'paypalWebhookDto', paypalWebhookDto)
            const localVarPath = `/public/paypal-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paypalWebhookDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddOneRoiCalcResultDto} addOneRoiCalcResultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerSaveRoiCalculatorResult: async (addOneRoiCalcResultDto: AddOneRoiCalcResultDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addOneRoiCalcResultDto' is not null or undefined
            assertParamExists('publicControllerSaveRoiCalculatorResult', 'addOneRoiCalcResultDto', addOneRoiCalcResultDto)
            const localVarPath = `/public/add-roi-calc-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOneRoiCalcResultDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerStripeWebhook: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('publicControllerStripeWebhook', 'body', body)
            const localVarPath = `/public/stripe-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyEmailInputDto} verifyEmailInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerValidateEmail: async (verifyEmailInputDto: VerifyEmailInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmailInputDto' is not null or undefined
            assertParamExists('publicControllerValidateEmail', 'verifyEmailInputDto', verifyEmailInputDto)
            const localVarPath = `/public/validate-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyPhoneInputDto} verifyPhoneInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerValidatePhoneNumber: async (verifyPhoneInputDto: VerifyPhoneInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyPhoneInputDto' is not null or undefined
            assertParamExists('publicControllerValidatePhoneNumber', 'verifyPhoneInputDto', verifyPhoneInputDto)
            const localVarPath = `/public/validate-phone-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyPhoneInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PaypalCaptureOrderInputDto} paypalCaptureOrderInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerCaptureLifeTimeAccessOrder(paypalCaptureOrderInputDto: PaypalCaptureOrderInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaypalCaptureOrderRespDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerCaptureLifeTimeAccessOrder(paypalCaptureOrderInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.publicControllerCaptureLifeTimeAccessOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MarketingUserDto} marketingUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerCreateJoinforLifePaymentIntent(marketingUserDto: MarketingUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripePaymentIntentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerCreateJoinforLifePaymentIntent(marketingUserDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.publicControllerCreateJoinforLifePaymentIntent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MarketingUserDto} marketingUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerCreateLifeTimeAccessOrder(marketingUserDto: MarketingUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaypalCreateOrderRespDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerCreateLifeTimeAccessOrder(marketingUserDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.publicControllerCreateLifeTimeAccessOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MarketingUserDto} marketingUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerCreateMarketingUser(marketingUserDto: MarketingUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketingUserOneRespDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerCreateMarketingUser(marketingUserDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.publicControllerCreateMarketingUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FetchWaitlistUserDto} fetchWaitlistUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerGetOneWaitlistUser(fetchWaitlistUserDto: FetchWaitlistUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketingUserOneRespDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerGetOneWaitlistUser(fetchWaitlistUserDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.publicControllerGetOneWaitlistUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PaypalWebhookDto} paypalWebhookDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerPaypalWebhook(paypalWebhookDto: PaypalWebhookDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerPaypalWebhook(paypalWebhookDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.publicControllerPaypalWebhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AddOneRoiCalcResultDto} addOneRoiCalcResultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerSaveRoiCalculatorResult(addOneRoiCalcResultDto: AddOneRoiCalcResultDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoiCalcRespDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerSaveRoiCalculatorResult(addOneRoiCalcResultDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.publicControllerSaveRoiCalculatorResult']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerStripeWebhook(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerStripeWebhook(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.publicControllerStripeWebhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VerifyEmailInputDto} verifyEmailInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerValidateEmail(verifyEmailInputDto: VerifyEmailInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerValidateEmail(verifyEmailInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.publicControllerValidateEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VerifyPhoneInputDto} verifyPhoneInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerValidatePhoneNumber(verifyPhoneInputDto: VerifyPhoneInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerValidatePhoneNumber(verifyPhoneInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.publicControllerValidatePhoneNumber']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * 
         * @param {PaypalCaptureOrderInputDto} paypalCaptureOrderInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerCaptureLifeTimeAccessOrder(paypalCaptureOrderInputDto: PaypalCaptureOrderInputDto, options?: any): AxiosPromise<PaypalCaptureOrderRespDto> {
            return localVarFp.publicControllerCaptureLifeTimeAccessOrder(paypalCaptureOrderInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MarketingUserDto} marketingUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerCreateJoinforLifePaymentIntent(marketingUserDto: MarketingUserDto, options?: any): AxiosPromise<StripePaymentIntentResponseDto> {
            return localVarFp.publicControllerCreateJoinforLifePaymentIntent(marketingUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MarketingUserDto} marketingUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerCreateLifeTimeAccessOrder(marketingUserDto: MarketingUserDto, options?: any): AxiosPromise<PaypalCreateOrderRespDto> {
            return localVarFp.publicControllerCreateLifeTimeAccessOrder(marketingUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MarketingUserDto} marketingUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerCreateMarketingUser(marketingUserDto: MarketingUserDto, options?: any): AxiosPromise<MarketingUserOneRespDto> {
            return localVarFp.publicControllerCreateMarketingUser(marketingUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FetchWaitlistUserDto} fetchWaitlistUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetOneWaitlistUser(fetchWaitlistUserDto: FetchWaitlistUserDto, options?: any): AxiosPromise<MarketingUserOneRespDto> {
            return localVarFp.publicControllerGetOneWaitlistUser(fetchWaitlistUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaypalWebhookDto} paypalWebhookDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPaypalWebhook(paypalWebhookDto: PaypalWebhookDto, options?: any): AxiosPromise<WebhookResponseDto> {
            return localVarFp.publicControllerPaypalWebhook(paypalWebhookDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddOneRoiCalcResultDto} addOneRoiCalcResultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerSaveRoiCalculatorResult(addOneRoiCalcResultDto: AddOneRoiCalcResultDto, options?: any): AxiosPromise<RoiCalcRespDto> {
            return localVarFp.publicControllerSaveRoiCalculatorResult(addOneRoiCalcResultDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerStripeWebhook(body: object, options?: any): AxiosPromise<WebhookResponseDto> {
            return localVarFp.publicControllerStripeWebhook(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyEmailInputDto} verifyEmailInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerValidateEmail(verifyEmailInputDto: VerifyEmailInputDto, options?: any): AxiosPromise<VerifyResponseDto> {
            return localVarFp.publicControllerValidateEmail(verifyEmailInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyPhoneInputDto} verifyPhoneInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerValidatePhoneNumber(verifyPhoneInputDto: VerifyPhoneInputDto, options?: any): AxiosPromise<VerifyResponseDto> {
            return localVarFp.publicControllerValidatePhoneNumber(verifyPhoneInputDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicApi - interface
 * @export
 * @interface PublicApi
 */
export interface PublicApiInterface {
    /**
     * 
     * @param {PaypalCaptureOrderInputDto} paypalCaptureOrderInputDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    publicControllerCaptureLifeTimeAccessOrder(paypalCaptureOrderInputDto: PaypalCaptureOrderInputDto, options?: RawAxiosRequestConfig): AxiosPromise<PaypalCaptureOrderRespDto>;

    /**
     * 
     * @param {MarketingUserDto} marketingUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    publicControllerCreateJoinforLifePaymentIntent(marketingUserDto: MarketingUserDto, options?: RawAxiosRequestConfig): AxiosPromise<StripePaymentIntentResponseDto>;

    /**
     * 
     * @param {MarketingUserDto} marketingUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    publicControllerCreateLifeTimeAccessOrder(marketingUserDto: MarketingUserDto, options?: RawAxiosRequestConfig): AxiosPromise<PaypalCreateOrderRespDto>;

    /**
     * 
     * @param {MarketingUserDto} marketingUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    publicControllerCreateMarketingUser(marketingUserDto: MarketingUserDto, options?: RawAxiosRequestConfig): AxiosPromise<MarketingUserOneRespDto>;

    /**
     * 
     * @param {FetchWaitlistUserDto} fetchWaitlistUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    publicControllerGetOneWaitlistUser(fetchWaitlistUserDto: FetchWaitlistUserDto, options?: RawAxiosRequestConfig): AxiosPromise<MarketingUserOneRespDto>;

    /**
     * 
     * @param {PaypalWebhookDto} paypalWebhookDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    publicControllerPaypalWebhook(paypalWebhookDto: PaypalWebhookDto, options?: RawAxiosRequestConfig): AxiosPromise<WebhookResponseDto>;

    /**
     * 
     * @param {AddOneRoiCalcResultDto} addOneRoiCalcResultDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    publicControllerSaveRoiCalculatorResult(addOneRoiCalcResultDto: AddOneRoiCalcResultDto, options?: RawAxiosRequestConfig): AxiosPromise<RoiCalcRespDto>;

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    publicControllerStripeWebhook(body: object, options?: RawAxiosRequestConfig): AxiosPromise<WebhookResponseDto>;

    /**
     * 
     * @param {VerifyEmailInputDto} verifyEmailInputDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    publicControllerValidateEmail(verifyEmailInputDto: VerifyEmailInputDto, options?: RawAxiosRequestConfig): AxiosPromise<VerifyResponseDto>;

    /**
     * 
     * @param {VerifyPhoneInputDto} verifyPhoneInputDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    publicControllerValidatePhoneNumber(verifyPhoneInputDto: VerifyPhoneInputDto, options?: RawAxiosRequestConfig): AxiosPromise<VerifyResponseDto>;

}

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI implements PublicApiInterface {
    /**
     * 
     * @param {PaypalCaptureOrderInputDto} paypalCaptureOrderInputDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerCaptureLifeTimeAccessOrder(paypalCaptureOrderInputDto: PaypalCaptureOrderInputDto, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicControllerCaptureLifeTimeAccessOrder(paypalCaptureOrderInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MarketingUserDto} marketingUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerCreateJoinforLifePaymentIntent(marketingUserDto: MarketingUserDto, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicControllerCreateJoinforLifePaymentIntent(marketingUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MarketingUserDto} marketingUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerCreateLifeTimeAccessOrder(marketingUserDto: MarketingUserDto, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicControllerCreateLifeTimeAccessOrder(marketingUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MarketingUserDto} marketingUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerCreateMarketingUser(marketingUserDto: MarketingUserDto, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicControllerCreateMarketingUser(marketingUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FetchWaitlistUserDto} fetchWaitlistUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerGetOneWaitlistUser(fetchWaitlistUserDto: FetchWaitlistUserDto, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicControllerGetOneWaitlistUser(fetchWaitlistUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaypalWebhookDto} paypalWebhookDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerPaypalWebhook(paypalWebhookDto: PaypalWebhookDto, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicControllerPaypalWebhook(paypalWebhookDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddOneRoiCalcResultDto} addOneRoiCalcResultDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerSaveRoiCalculatorResult(addOneRoiCalcResultDto: AddOneRoiCalcResultDto, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicControllerSaveRoiCalculatorResult(addOneRoiCalcResultDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerStripeWebhook(body: object, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicControllerStripeWebhook(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyEmailInputDto} verifyEmailInputDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerValidateEmail(verifyEmailInputDto: VerifyEmailInputDto, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicControllerValidateEmail(verifyEmailInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyPhoneInputDto} verifyPhoneInputDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerValidatePhoneNumber(verifyPhoneInputDto: VerifyPhoneInputDto, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicControllerValidatePhoneNumber(verifyPhoneInputDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ShareduledUserUpdatePasswordDto} shareduledUserUpdatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerChangePassword: async (shareduledUserUpdatePasswordDto: ShareduledUserUpdatePasswordDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareduledUserUpdatePasswordDto' is not null or undefined
            assertParamExists('userControllerChangePassword', 'shareduledUserUpdatePasswordDto', shareduledUserUpdatePasswordDto)
            const localVarPath = `/user/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareduledUserUpdatePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetProfile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ShareduledUserLoginDto} shareduledUserLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLoginByEmail: async (shareduledUserLoginDto: ShareduledUserLoginDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareduledUserLoginDto' is not null or undefined
            assertParamExists('userControllerLoginByEmail', 'shareduledUserLoginDto', shareduledUserLoginDto)
            const localVarPath = `/user/email-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareduledUserLoginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordCodeInputDto} forgotPasswordCodeInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSendForgotPasswordCode: async (forgotPasswordCodeInputDto: ForgotPasswordCodeInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordCodeInputDto' is not null or undefined
            assertParamExists('userControllerSendForgotPasswordCode', 'forgotPasswordCodeInputDto', forgotPasswordCodeInputDto)
            const localVarPath = `/user/send-forgot-password-verification-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordCodeInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ShareduledUserEntityDto} shareduledUserEntityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSignUpByEmail: async (shareduledUserEntityDto: ShareduledUserEntityDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareduledUserEntityDto' is not null or undefined
            assertParamExists('userControllerSignUpByEmail', 'shareduledUserEntityDto', shareduledUserEntityDto)
            const localVarPath = `/user/email-sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareduledUserEntityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ShareduledUserUpdateProfileDto} shareduledUserUpdateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateProfile: async (shareduledUserUpdateProfileDto: ShareduledUserUpdateProfileDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareduledUserUpdateProfileDto' is not null or undefined
            assertParamExists('userControllerUpdateProfile', 'shareduledUserUpdateProfileDto', shareduledUserUpdateProfileDto)
            const localVarPath = `/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareduledUserUpdateProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyForgotPasswordCodeInputDto} verifyForgotPasswordCodeInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerVerifyForgotPasswordCode: async (verifyForgotPasswordCodeInputDto: VerifyForgotPasswordCodeInputDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyForgotPasswordCodeInputDto' is not null or undefined
            assertParamExists('userControllerVerifyForgotPasswordCode', 'verifyForgotPasswordCodeInputDto', verifyForgotPasswordCodeInputDto)
            const localVarPath = `/user/verify-forgot-password-verification-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyForgotPasswordCodeInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ShareduledUserUpdatePasswordDto} shareduledUserUpdatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerChangePassword(shareduledUserUpdatePasswordDto: ShareduledUserUpdatePasswordDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseServiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerChangePassword(shareduledUserUpdatePasswordDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userControllerChangePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetProfile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareduledUserEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetProfile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userControllerGetProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ShareduledUserLoginDto} shareduledUserLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerLoginByEmail(shareduledUserLoginDto: ShareduledUserLoginDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareduledUserOneRespDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerLoginByEmail(shareduledUserLoginDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userControllerLoginByEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ForgotPasswordCodeInputDto} forgotPasswordCodeInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerSendForgotPasswordCode(forgotPasswordCodeInputDto: ForgotPasswordCodeInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseServiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerSendForgotPasswordCode(forgotPasswordCodeInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userControllerSendForgotPasswordCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ShareduledUserEntityDto} shareduledUserEntityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerSignUpByEmail(shareduledUserEntityDto: ShareduledUserEntityDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareduledUserOneRespDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerSignUpByEmail(shareduledUserEntityDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userControllerSignUpByEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ShareduledUserUpdateProfileDto} shareduledUserUpdateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateProfile(shareduledUserUpdateProfileDto: ShareduledUserUpdateProfileDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareduledUserEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateProfile(shareduledUserUpdateProfileDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userControllerUpdateProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VerifyForgotPasswordCodeInputDto} verifyForgotPasswordCodeInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerVerifyForgotPasswordCode(verifyForgotPasswordCodeInputDto: VerifyForgotPasswordCodeInputDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseServiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerVerifyForgotPasswordCode(verifyForgotPasswordCodeInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userControllerVerifyForgotPasswordCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {ShareduledUserUpdatePasswordDto} shareduledUserUpdatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerChangePassword(shareduledUserUpdatePasswordDto: ShareduledUserUpdatePasswordDto, options?: any): AxiosPromise<BaseServiceResponse> {
            return localVarFp.userControllerChangePassword(shareduledUserUpdatePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetProfile(options?: any): AxiosPromise<ShareduledUserEntity> {
            return localVarFp.userControllerGetProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ShareduledUserLoginDto} shareduledUserLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerLoginByEmail(shareduledUserLoginDto: ShareduledUserLoginDto, options?: any): AxiosPromise<ShareduledUserOneRespDto> {
            return localVarFp.userControllerLoginByEmail(shareduledUserLoginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordCodeInputDto} forgotPasswordCodeInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSendForgotPasswordCode(forgotPasswordCodeInputDto: ForgotPasswordCodeInputDto, options?: any): AxiosPromise<BaseServiceResponse> {
            return localVarFp.userControllerSendForgotPasswordCode(forgotPasswordCodeInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ShareduledUserEntityDto} shareduledUserEntityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSignUpByEmail(shareduledUserEntityDto: ShareduledUserEntityDto, options?: any): AxiosPromise<ShareduledUserOneRespDto> {
            return localVarFp.userControllerSignUpByEmail(shareduledUserEntityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ShareduledUserUpdateProfileDto} shareduledUserUpdateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateProfile(shareduledUserUpdateProfileDto: ShareduledUserUpdateProfileDto, options?: any): AxiosPromise<ShareduledUserEntity> {
            return localVarFp.userControllerUpdateProfile(shareduledUserUpdateProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyForgotPasswordCodeInputDto} verifyForgotPasswordCodeInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerVerifyForgotPasswordCode(verifyForgotPasswordCodeInputDto: VerifyForgotPasswordCodeInputDto, options?: any): AxiosPromise<BaseServiceResponse> {
            return localVarFp.userControllerVerifyForgotPasswordCode(verifyForgotPasswordCodeInputDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
    /**
     * 
     * @param {ShareduledUserUpdatePasswordDto} shareduledUserUpdatePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerChangePassword(shareduledUserUpdatePasswordDto: ShareduledUserUpdatePasswordDto, options?: RawAxiosRequestConfig): AxiosPromise<BaseServiceResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerGetProfile(options?: RawAxiosRequestConfig): AxiosPromise<ShareduledUserEntity>;

    /**
     * 
     * @param {ShareduledUserLoginDto} shareduledUserLoginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerLoginByEmail(shareduledUserLoginDto: ShareduledUserLoginDto, options?: RawAxiosRequestConfig): AxiosPromise<ShareduledUserOneRespDto>;

    /**
     * 
     * @param {ForgotPasswordCodeInputDto} forgotPasswordCodeInputDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerSendForgotPasswordCode(forgotPasswordCodeInputDto: ForgotPasswordCodeInputDto, options?: RawAxiosRequestConfig): AxiosPromise<BaseServiceResponse>;

    /**
     * 
     * @param {ShareduledUserEntityDto} shareduledUserEntityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerSignUpByEmail(shareduledUserEntityDto: ShareduledUserEntityDto, options?: RawAxiosRequestConfig): AxiosPromise<ShareduledUserOneRespDto>;

    /**
     * 
     * @param {ShareduledUserUpdateProfileDto} shareduledUserUpdateProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerUpdateProfile(shareduledUserUpdateProfileDto: ShareduledUserUpdateProfileDto, options?: RawAxiosRequestConfig): AxiosPromise<ShareduledUserEntity>;

    /**
     * 
     * @param {VerifyForgotPasswordCodeInputDto} verifyForgotPasswordCodeInputDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerVerifyForgotPasswordCode(verifyForgotPasswordCodeInputDto: VerifyForgotPasswordCodeInputDto, options?: RawAxiosRequestConfig): AxiosPromise<BaseServiceResponse>;

}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
    /**
     * 
     * @param {ShareduledUserUpdatePasswordDto} shareduledUserUpdatePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerChangePassword(shareduledUserUpdatePasswordDto: ShareduledUserUpdatePasswordDto, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerChangePassword(shareduledUserUpdatePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetProfile(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerGetProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShareduledUserLoginDto} shareduledUserLoginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerLoginByEmail(shareduledUserLoginDto: ShareduledUserLoginDto, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerLoginByEmail(shareduledUserLoginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordCodeInputDto} forgotPasswordCodeInputDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerSendForgotPasswordCode(forgotPasswordCodeInputDto: ForgotPasswordCodeInputDto, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerSendForgotPasswordCode(forgotPasswordCodeInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShareduledUserEntityDto} shareduledUserEntityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerSignUpByEmail(shareduledUserEntityDto: ShareduledUserEntityDto, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerSignUpByEmail(shareduledUserEntityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShareduledUserUpdateProfileDto} shareduledUserUpdateProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdateProfile(shareduledUserUpdateProfileDto: ShareduledUserUpdateProfileDto, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerUpdateProfile(shareduledUserUpdateProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyForgotPasswordCodeInputDto} verifyForgotPasswordCodeInputDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerVerifyForgotPasswordCode(verifyForgotPasswordCodeInputDto: VerifyForgotPasswordCodeInputDto, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerVerifyForgotPasswordCode(verifyForgotPasswordCodeInputDto, options).then((request) => request(this.axios, this.basePath));
    }
}



