import { MarketingUserDto } from "../../sdk/open-api";
import { IResponse, publicApiClient } from "./main";

export interface ICreatePaymentIntentResponse extends IResponse {
  clientSecret?: string;
}

export const createPaymentIntentForLifeTimeAccess = async (
  data: MarketingUserDto
): Promise<ICreatePaymentIntentResponse> => {
  try {
    const resp = (
      await publicApiClient.publicControllerCreateJoinforLifePaymentIntent(data)
    )?.data;

    if (resp?.code !== 200 || !resp?.clientSecret) throw resp;

    return {
      success: true,
      clientSecret: resp.clientSecret,
    };
  } catch (e: any) {
    console.warn("CPIFLTA Api Error: ", e);
    return {
      success: false,
      message:
        typeof e === "string"
          ? e
          : e?.message ?? `Failure for ${data.business_email}`,
    };
  }
};
