import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function PrivacyPolicies() {
  return (
    <Stack spacing={{ xs: 2, sm: 3 }} useFlexGap>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        1. Introduction
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        This Data Collection and Privacy Policy ("Policy") applies to your use
        of our website and services (collectively, the "Service") provided by
        Shareduled. This Policy describes the information we collect from you,
        how we use that information, and your rights associated with that
        information. We are committed to protecting your privacy and complying
        with all applicable data privacy laws and regulations, including the
        General Data Protection Regulation (GDPR) of the European Union, the
        California Consumer Privacy Act (CCPA) of the United States, and the
        Personal Information Protection and Electronic Documents Act (PIPEDA) of
        Canada.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        2. Information We Collect
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We collect two types of information through the Service:
      </Typography>

      <List sx={{ pl: { xs: 3, sm: 4, md: 5 } }}>
        {[
          "Personal Information: This is data that can be used to identify you as an individual. It may include information such as your name, email address, phone number, billing address, and IP address. We only collect personal information that is necessary to provide the Service or that you voluntarily provide to us.",
          "Non-Personal Information: This is data that cannot be used to identify you as an individual. It may include information such as your browsing history, search queries, and device information. We collect non-personal information automatically when you use the Service.",
        ].map((x, index) => (
          <ListItem disablePadding key={index.toString()}>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant="body2" style={{ fontWeight: "300" }}>
                {x}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        3. Use of Information
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We use the information we collect for the following purposes:
      </Typography>

      <List sx={{ pl: { xs: 3, sm: 4, md: 5 } }}>
        {[
          "To provide and operate the Service",
          "To personalize your experience with the Service",
          "To send you marketing communications (with your consent)",
          "To respond to your inquiries and requests",
          "To improve the Service",
          "To comply with legal and regulatory requirements",
        ].map((x, index) => (
          <ListItem disablePadding key={index.toString()}>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant="body2" style={{ fontWeight: "300" }}>
                {x}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        4. Your Rights
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        You have certain rights with respect to your personal information. These
        rights may vary depending on your location. You may have the right to:
      </Typography>

      <List sx={{ pl: { xs: 3, sm: 4, md: 5 } }}>
        {[
          "Access your personal information",
          "Correct inaccurate personal information",
          "Request deletion of your personal information",
          "Object to the processing of your personal information",
          "To improve the Service",
          "Withdraw your consent (where applicable)",
        ].map((x, index) => (
          <ListItem disablePadding key={index.toString()}>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant="body2" style={{ fontWeight: "300" }}>
                {x}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We will respond to your requests within the timeframe required by
        applicable law.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        5. Data Retention and Security
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We will retain your personal information for as long as necessary to
        provide the Service, comply with legal and regulatory requirements, or
        resolve disputes. We take reasonable security measures to protect your
        personal information from unauthorized access, disclosure, alteration,
        or destruction.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        6. Sharing of Information
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We do not sell, trade, or otherwise transfer your personal information
        to outside parties except as described in this Policy. This does not
        include trusted third parties who assist us in operating our website,
        conducting our business, or servicing you, so long as those parties
        agree to keep this information confidential. We may also release your
        information when we believe release is appropriate to comply with the
        law, enforce our site policies, or protect ours or others' rights,
        property, or safety.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        7. Cookies and Tracking Technologies
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We use cookies and similar tracking technologies to track the activity
        on our Service and hold certain information. Cookies are files with a
        small amount of data which may include an anonymous unique identifier.
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        8. Third-Party Services
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        Our Service may contain links to other sites that are not operated by
        us. If you click on a third-party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        of every site you visit. We have no control over and assume no
        responsibility for the content, privacy policies, or practices of any
        third-party sites or services.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        9. Children's Privacy
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        Our Service does not address anyone under the age of 13 ("Children"). We
        do not knowingly collect personally identifiable information from
        children under 13. If we become aware that we have collected personal
        information from children without verification of parental consent, we
        take steps to remove that information from our servers.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        10. Changes to This Policy
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We may update our Data Collection and Privacy Policy from time to time.
        We will notify you of any changes by posting the new Policy on this
        page. You are advised to review this Policy periodically for any
        changes. Changes to this Policy are effective when they are posted on
        this page.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        11. Contact Us
      </Typography>

      <Typography
        variant="body1"
        sx={{ fontWeight: "300", mb: { xs: 4, sm: 5, md: 6 } }}
      >
        If you have any questions about this Policy, please contact us at&nbsp;
        <Link
          color="inherit"
          href="mailto:support@shareduled.com?subject=About Shareduled Privacy Policy&body=Hello Team Shareduled,"
          target="_blank"
          rel="noopener noreferrer"
        >
          support@shareduled.com
        </Link>
      </Typography>

      <Typography
        variant="body1"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We value your privacy and are committed to protecting your personal
        information. Thank you for trusting Shareduled.
      </Typography>
    </Stack>
  );
}
