import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { AppColours } from "../../helpers/colors";
import { scrollToSection } from "../../helpers/miscellaneous";

export default function SummarySection() {
  return (
    <Container
      maxWidth="lg"
      id="summary-section"
      sx={{
        minHeight: "inherit",
        justifyContent: "center",
        alignItems: "center",
        pb: { xs: 10, sm: 10 },
      }}
    >
      <Grid
        container
        spacing={{ xs: 8, sm: 8 }}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "inherit",
          flexDirection: { xs: "column-reverse", sm: "row" },
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            spacing={{ xs: 2, sm: 3 }}
            useFlexGap
            sx={{
              height: "inherit",
              width: { xs: "100%", sm: "100%" },
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              pt: { xs: 10, sm: 20 },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                display: "flex",
                alignSelf: "center",
                flexWrap: "wrap",
                textAlign: "left",
              }}
            >
              Empower Your Business with&nbsp;
              <Typography
                component="span"
                variant="h4"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "primary.main"
                      : "primary.light",
                  fontWeight: "bolder",
                }}
              >
                Intelligent Scheduling&nbsp;
              </Typography>
              and&nbsp;
              <Typography
                component="span"
                variant="h4"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "primary.main"
                      : "primary.light",
                  fontWeight: "bolder",
                }}
              >
                Real-Time Bookings
              </Typography>
            </Typography>

            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignSelf: "center",
                flexWrap: "wrap",
                textAlign: "left",
                fontWeight: "300",
              }}
            >
              Free your staff, take back your time, grow your business, increase
              revenue and focus on what matters.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignSelf: "center",
                flexWrap: "wrap",
                textAlign: "left",
                fontWeight: "300",
                pb: { xs: 3, sm: 3 },
              }}
            >
              Experience the ease of flexible, real-time appointment management
              that delights customers and drives loyalty while leveraging the
              power of live updates and smart management to outpace the
              competition.
            </Typography>

            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => {
                scrollToSection("shareduled-power-section");
              }}
              sx={{
                borderRadius: "8px",
                textTransform: "none",
                backgroundColor: AppColours.MainRed,
                padding: "12px",
              }}
            >
              Take the Free ROI Test & See the Results
            </Button>
            <Button
              color="primary"
              variant="text"
              fullWidth
              onClick={() => {
                scrollToSection("pricing-and-start-section");
              }}
              sx={{
                borderRadius: "8px",
                textTransform: "none",
                textDecoration: "underline",
              }}
            >
              Skip and Get Life Time Access Now
            </Button>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              maxHeight: "100vh",
              pt: { xs: 4, sm: 6 },
            }}
          >
            <CardMedia
              component="img"
              loading="lazy"
              src="image"
              image={
                "https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/empower-your-business.png"
              }
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: { xs: 360, sm: 560, md: 560 },
                borderRadius: 5,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
