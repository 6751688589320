import React, { useState } from "react";
import LayoutV2 from "../layout/LayoutV2";
import LeadMagnetJoinForm from "../lead-magnet/LeadMagnetJoinForm";
import { ShareduledUser } from "./LeadMagnetScreen";

export default function LeadMagnetJoinFormScreen() {
  const [currentUser, setCurrentUser] = useState<ShareduledUser>({
    firstName: "",
    lastName: "",
    country: "",
    countryShortCode: "ca",
    phoneNumber: "",
    phoneNumberCountryName: "",
    phoneNumberCountryShortCode: "",
    businessName: "",
    businessEmail: "",
    ageRange: null,
    businessEmailValid: false,
    phoneNumberValid: false,
  });

  return (
    <LayoutV2
      hideNavLinks
      hideFooterNav
      hideFooterResources
      child={
        <React.Fragment>
          <LeadMagnetJoinForm {...currentUser} setUser={setCurrentUser} />
        </React.Fragment>
      }
    />
  );
}
