import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AppColours } from "../../helpers/colors";
import { useNavigate } from "react-router-dom";

export default function ShareduledPower() {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="lg"
      id="shareduled-power-section"
      sx={{
        minHeight: "inherit",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pt: { xs: 4, sm: 6 },
        pb: { xs: 4, sm: 6 },
        backgroundColor: AppColours.LightPurple,
      }}
    >
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: (theme) => {
            return {
              [theme.breakpoints.down("sm")]: {
                display: "flex-start",
              },
            };
          },
          pb: { xs: 2, sm: 4 },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            color: (theme) => theme?.palette?.primary?.main,
            display: "flex",
            alignSelf: (theme) => {
              return {
                [theme.breakpoints.down("sm")]: {
                  display: "flex-start",
                },
              };
            },
            flexWrap: "wrap",
            textAlign: "left",
          }}
        >
          Don't Guess, Let's Reveal the Power of Shareduled's Smart Scheduling
          So You Can Project Your Profits with Precision
        </Typography>
      </Box>

      <Grid
        container
        direction="row"
        spacing={{ xs: 8, sm: 8 }}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "inherit",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              pt: { xs: 4, sm: 0 },
            }}
          >
            <CardMedia
              component="img"
              loading="lazy"
              src="image"
              image="https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/dont-guess.png"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: { xs: 600, sm: 880 },
                borderRadius: 5,
              }}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "inherit",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            spacing={{ xs: 2, sm: 3 }}
            useFlexGap
            sx={{
              height: "inherit",
              width: { xs: "100%", sm: "100%" },
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              pb: { xs: 2, sm: 4 },
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: "500",
                fontStyle: "italic",
                pb: { xs: 2, sm: 2, md: 3 },
              }}
            >
              Want to see real numbers on the impact Shareduled can make on your
              revenue and efficiency?
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontWeight: "300", pb: { xs: 2, sm: 2, md: 3 } }}
            >
              Imagine a future where every minute translates to money well
              earned. Every business move should be a step towards greater
              profits and our ROI calculator paints a clear picture of this
              future, showing you the direct impact of Shareduled on your
              finances.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: "300",
                pb: { xs: 2, sm: 2, md: 3 },
                color: AppColours.MainRed,
              }}
            >
              Take control of your earnings today, dive into the numbers and
              start making every second count. The future is bright, and it's
              just one click away.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ fontWeight: "300" }}
            >
              We will show you so that you can see how you can:
            </Typography>
            <List sx={{ mb: { xs: 4, sm: 5, md: 6 } }}>
              {[
                "Make informed decisions about your revenue and profitability.",
                "Unlock hidden profit potential within your business.",
                "Assess suitability of Shareduled based on data.",
                "Make a confident investment decision for your business.",
                "Gain a clear understanding of Shareduled's impact.",
              ].map((x, index) => (
                <ListItem disablePadding key={index.toString()}>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    <Typography variant="body1" style={{ fontWeight: "300" }}>
                      {x}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Stack>
        </Grid>
      </Grid>

      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          navigate("/welcome");
        }}
        sx={{
          alignSelf: "center",
          borderRadius: "8px",
          textTransform: "none",
          backgroundColor: AppColours.MainRed,
          padding: "12px",
          width: "100%",
        }}
      >
        Discover My Profit Potential - Analyze My ROI
      </Button>
    </Container>
  );
}
