import React, { useEffect, useState } from "react";
import LayoutV2 from "../layout/LayoutV2";
import { useLocation, useNavigate } from "react-router-dom";
import { MarketingUserDto } from "../../sdk/open-api";
import ComingSoonComponent from "../landing-page/ComingSoonComponent";

export default function ComingSoonScreen() {
  let location = useLocation();
  let navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState<MarketingUserDto | null>(null);
  const [existing, setExisting] = useState(false);

  useEffect(() => {
    const locationData = location.state as {
      user: MarketingUserDto | null;
      existing: boolean;
    };

    if (locationData?.user) {
      setCurrentUser(locationData.user as unknown as MarketingUserDto);
      setExisting(locationData?.existing ?? false);
    } else {
      // Redirect user to screen to fill form
      navigate("/welcome");
    }
  }, [location, currentUser, navigate]);

  return (
    <LayoutV2
      hideNavLinks
      hideFooterNav
      hideFooterResources
      child={
        <React.Fragment>
          <ComingSoonComponent user={currentUser} existing={existing} />
        </React.Fragment>
      }
    />
  );
}
