import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { AppColours } from "../../helpers/colors";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useRef, useState } from "react";
import { scrollToSection } from "../../helpers/miscellaneous";

type ISEData = {
  header: string;
  src: "image" | "video";
  subHeader: string;
  textBlack1: string;
  textRed1: string;
  textBlack2: string;
  textList?: string[];
};

const data: ISEData[] = [
  {
    header: "A Vision for Seamless Scheduling",
    src: "video",
    subHeader: "How we started. The first “rough” sketches in motion",
    textBlack1: `At the heart of Shareduled is a vision for a world where scheduling is no longer a hurdle but a stepping stone to success. Our relentless pursuit of this vision has shaped every aspect of Shareduled. Think real-time, live updates and live changes.`,
    textRed1: "Think Shareduled",
    textBlack2: ".",
  },
  {
    header: "The Power of Scheduling: Data-Driven Benefits for Your Business",
    src: "image",
    subHeader: "According to Zippia Research",
    textBlack1:
      "Shareduled can help businesses save up to 10 hours per week through automation and streamline operations, leading to a potential ",
    textRed1: "yearly 20% increase in revenue",
    textBlack2: ".",
  },
  {
    header:
      "Your Data, Your Security: How Shareduled Prioritizes Privacy Content",
    src: "image",
    subHeader: "Your data is more secure than your safe at home",
    textBlack1: "We understand the importance of protecting your client data. ",
    textRed1:
      "Shareduled prioritizes security and privacy with industry-leading practices",
    textBlack2: ":",
    textList: [
      "256-bit encryption safeguards your data both in transit and at rest.",
      "Secure cloud storage with trusted providers ensures data accessibility and protection.",
      "Regular backups guarantee information recovery in case of unforeseen circumstances.",
    ],
  },
  {
    header: "Listening Today, Improving Tomorrow",
    src: "image",
    subHeader: "Turn your feedback to features that matter",
    textBlack1:
      "Your feedback is the compass that guides our service improvements. ",
    textRed1:
      "Shareduled listens to your needs and continuously evolves to serve you better",
    textBlack2: ".",
  },
];

const ServiceExcellenceInternal = (
  props: ISEData & {
    image: any;
  }
) => {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!ref.current) return;

    const resizeObserver = new ResizeObserver(() => {
      const { offsetWidth } = ref.current as any;
      setWidth(offsetWidth);
    });

    resizeObserver.observe(ref.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Grid
      ref={ref}
      item
      xs={12}
      sm={6}
      sx={{
        height: "inherit",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        elevation={2}
        raised
        sx={{
          borderRadius: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          pr: { xs: 3, sm: 4, md: 4 },
          pl: { xs: 3, sm: 4, md: 4 },
          pt: { xs: 3, sm: 4, md: 5 },
          pb: { xs: 3, sm: 4, md: 5 },
          boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            lineHeight: "26px",
            textAlign: "center",
            fontWeight: "600",
            color: AppColours.MainGreen,
            pt: { xs: 3, sm: 4, md: 5 },
            pb: { xs: 2, sm: 3, md: 4 },
          }}
        >
          {props?.header}
        </Typography>

        <CardMedia
          component={props?.src === "video" ? "video" : "img"}
          loading="lazy"
          src={props?.src}
          image={props?.image}
          autoPlay={props?.src === "video" ? true : undefined}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: { xs: 150, sm: 150, md: 240 },
            width: 0.8 * width,
          }}
          controls={props?.src === "video" ? true : undefined}
          controlsList={props?.src === "video" ? "nodownload" : undefined}
        />

        <Typography
          variant="body2"
          sx={{
            lineHeight: "26px",
            textAlign: "center",
            fontWeight: "600",
            color: AppColours.MainBlack,
            pt: { xs: 3, sm: 4, md: 5 },
            pb: { xs: 2, sm: 3, md: 4 },
          }}
        >
          {props?.subHeader}
        </Typography>

        <Typography
          variant="body1"
          component={"div"}
          sx={{
            lineHeight: "26px",
            textAlign: "left",
            fontWeight: "300",
            color: AppColours.MainBlack,
            pb: { xs: 2, sm: 3, md: 4 },
          }}
        >
          {props?.textBlack1}&nbsp;
          <Typography
            variant="body1"
            sx={{
              display: "inline",
              lineHeight: "26px",
              fontWeight: "300",
              color: AppColours.MainRed,
            }}
          >
            {props?.textRed1}
          </Typography>
          {props?.textBlack2}
        </Typography>

        {props?.textList && (
          <List>
            {props.textList.map((x, index) => (
              <ListItem disablePadding key={index.toString()}>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body2" style={{ fontWeight: "200" }}>
                    {x}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </Card>
    </Grid>
  );
};

export default function ServiceExcellence() {
  return (
    <Container
      maxWidth="lg"
      id="service-excellence-section"
      sx={{
        minHeight: "inherit",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pt: { xs: 4, sm: 6 },
        pb: { xs: 8, sm: 10 },
      }}
    >
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: (theme) => {
            return {
              [theme.breakpoints.down("sm")]: {
                display: "flex-start",
              },
            };
          },
          pb: { xs: 6, sm: 8 },
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: "600",
            color: (theme) => theme?.palette?.primary?.main,
            display: "flex",
            alignSelf: (theme) => {
              return {
                [theme.breakpoints.down("sm")]: {
                  display: "flex-start",
                },
              };
            },
            flexWrap: "wrap",
            textAlign: "left",
          }}
        >
          Service Excellence: Our Promise to You
        </Typography>
      </Box>

      <Grid
        container
        spacing={{ xs: 5, sm: 6, md: 7 }}
        sx={{
          mb: { xs: 4, sm: 5, md: 6 },
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "inherit",
        }}
      >
        {data.map((x, index) => {
          let imageData =
            "https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/how-we-started-to-where-we-are.mp4";

          switch (index) {
            case 1:
              imageData = require("../../assets/langing-page/Zippia-Research.png");
              break;
            case 2:
              imageData = require("../../assets/langing-page/An-icon-of-a-secure-lock-with-a-checkmark-representing-data-security-alongside-a-brief-explanation.png");
              break;
            case 3:
              imageData =
                "https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/feedback-to-shareduled.jpg";
              break;

            default:
              break;
          }

          return (
            <ServiceExcellenceInternal
              key={index.toString()}
              {...x}
              image={imageData}
            />
          );
        })}
      </Grid>

      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          scrollToSection("shareduled-power-section");
        }}
        sx={{
          alignSelf: "center",
          borderRadius: "8px",
          textTransform: "none",
          backgroundColor: AppColours.MainRed,
          padding: "12px",
          width: { xs: "100%", sm: "80%", md: "60%" },
        }}
      >
        Take Back Your Time: Start Today
      </Button>
    </Container>
  );
}
