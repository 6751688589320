import { MarketingUserDto } from "../../sdk/open-api";
import { publicApiClient } from "./main";

export const createOrder = async (data: MarketingUserDto) => {
  try {
    const resp = (
      await publicApiClient.publicControllerCreateLifeTimeAccessOrder(data)
    )?.data;

    if (resp?.code !== 200 || !resp?.data || !resp?.data?.id) throw resp;

    return resp.data?.id;
  } catch (e) {
    console.warn("PCO Api Error: ", e);
    return "";
  }
};

export const captureOrderOnApproval = async (orderID: string) => {
  try {
    const resp = (
      await publicApiClient.publicControllerCaptureLifeTimeAccessOrder({
        orderID,
      })
    )?.data;

    if (resp?.code !== 200 || !resp?.data) throw resp;

    return resp.data;
  } catch (e) {
    console.warn("PCOOA Api Error: ", e);
    return;
  }
};
