import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import { scrollToSection } from "../../helpers/miscellaneous";
import MoneyIcon from "@mui/icons-material/Money";
import PolicyIcon from "@mui/icons-material/Policy";
import { useState } from "react";
import XIcon from "@mui/icons-material/X";
import TermsAndConditions from "./Legal/TermsAndConditions";
import PrivacyPolicies from "./Legal/PrivacyPolicies";
import PricingPolicies from "./Legal/PricingPolicies";

type LegalData = {
  header: string;
  body: JSX.Element;
};

const LEGAL_DATA: { [key: string]: LegalData } = {
  terms: {
    header: "Terms and Conditions",
    body: <TermsAndConditions />,
  },
  "privacy-policy": {
    header: "Data Collection and Privacy Policies",
    body: <PrivacyPolicies />,
  },
  pricing: {
    header: "Our Pricing",
    body: <PricingPolicies />,
  },
};

type FooterProps = {
  hideNavigation?: boolean;
  hideResources?: boolean;
};

type LegalModalProps = {
  open: boolean;
  handleClose: () => void;
  currentLegal: string;
};

function LegalInfoModal(props: LegalModalProps) {
  const { open, handleClose, currentLegal } = props;

  const data = LEGAL_DATA[currentLegal];

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>{data?.header ?? ""}</DialogTitle>
        <DialogContent>{data?.body}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Okay</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default function Footer(props: FooterProps) {
  const { hideNavigation, hideResources } = props;

  const [open, setOpen] = useState(false);

  const [currentLegal, setCurrentLegal] = useState("privacy-policy");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container
      maxWidth="lg"
      id="footer-section"
      sx={{
        minHeight: "inherit",
        justifyContent: "center",
        alignItems: "center",
        pt: { xs: 8, sm: 10, md: 12 },
      }}
    >
      <LegalInfoModal
        open={open}
        handleClose={handleClose}
        currentLegal={currentLegal}
      />

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        useFlexGap
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          pb: { xs: 2, sm: 3 },
        }}
      >
        <Box>
          <Typography variant="body2" color="primary" mt={1}>
            {"© "}
            <Link underline="none" href="#">
              Shareduled,&nbsp;
            </Link>
            {new Date().getFullYear()}
          </Typography>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            useFlexGap
            sx={{
              width: "inherit",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              pt: { xs: 1, sm: 2 },
              pb: { xs: 2, sm: 3 },
            }}
          >
            <IconButton
              href="https://www.instagram.com/shareduled/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon color="primary" />
            </IconButton>
            <IconButton
              href="https://www.tiktok.com/@shareduled"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MusicNoteIcon color="primary" />
            </IconButton>
            <IconButton
              href="https://www.facebook.com/shareduled/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon color="primary" />
            </IconButton>
            <IconButton
              href="https://x.com/shareduled"
              target="_blank"
              rel="noopener noreferrer"
            >
              <XIcon color="primary" />
            </IconButton>
            <IconButton
              href="https://www.youtube.com/channel/UCpBD7Lw_bwYSWqoDHvr6EEw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTubeIcon color="primary" />
            </IconButton>
          </Stack>
        </Box>
        {!hideNavigation && (
          <Box>
            <Typography variant="body2" color="primary">
              Navigation
            </Typography>
            <List>
              {[
                { name: "About Us", id: "service-excellence-section" },
                { name: "Features", id: "business-features-section" },
                { name: "Contact", id: "" },
              ].map((x, index) => (
                <ListItem
                  key={index.toString()}
                  disablePadding
                  onClick={
                    x?.name?.toLowerCase() === "contact"
                      ? () => {}
                      : () => scrollToSection(x.id)
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <ListItemText disableTypography>
                    <Link
                      underline="none"
                      color="inherit"
                      href={
                        x?.name?.toLowerCase() === "contact"
                          ? "mailto:mia.chloe@shareduled.com?subject=Question About Shareduled&body=Hello Team Shareduled,"
                          : undefined
                      }
                      target={
                        x?.name?.toLowerCase() === "contact"
                          ? "_blank"
                          : undefined
                      }
                      rel={
                        x?.name?.toLowerCase() === "contact"
                          ? "noopener noreferrer"
                          : undefined
                      }
                    >
                      <Typography variant="body1" style={{ fontWeight: "300" }}>
                        {x?.name}
                      </Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        {!hideResources && (
          <Box>
            <Typography variant="body2" color="primary">
              Resources
            </Typography>
            <List>
              <ListItem
                disablePadding
                onClick={() => scrollToSection("faq-section")}
                sx={{
                  cursor: "pointer",
                }}
              >
                <ListItemText disableTypography>
                  <Link underline="none" color="inherit" onClick={() => {}}>
                    <Typography variant="body1" style={{ fontWeight: "300" }}>
                      FAQ
                    </Typography>
                  </Link>
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        )}
        <Box>
          <Typography variant="body2" color="primary">
            Legal and Company Information
          </Typography>
          <List>
            {[
              { title: "location", details: "With ❤️ from Ontario, Canada" },
              { title: "terms", details: "" },
              { title: "privacy-policy", details: "" },
              { title: "pricing", details: "" },
              { title: "email", details: "" },
            ].map((x, index) => {
              let iconNode = (
                <MyLocationIcon color="primary" fontSize="small" />
              );

              switch (x?.title) {
                case "email":
                  iconNode = (
                    <AttachEmailIcon color="primary" fontSize="small" />
                  );
                  break;
                case "privacy-policy":
                  iconNode = <PolicyIcon color="primary" fontSize="small" />;
                  break;
                case "pricing":
                  iconNode = <MoneyIcon color="primary" fontSize="small" />;
                  break;
                case "terms":
                  iconNode = <PolicyIcon color="primary" fontSize="small" />;
                  break;

                default:
                  break;
              }

              return (
                <ListItem
                  key={index.toString()}
                  disablePadding
                  dense
                  alignItems="flex-start"
                  disableGutters
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "space-around",
                    cursor: x.title === "email" ? "pointer" : "default",
                    pr: { xs: 1, sm: 2, md: 3 },
                  }}
                >
                  <ListItemIcon sx={{ mr: 0, pr: 0, alignSelf: "center" }}>
                    {iconNode}
                  </ListItemIcon>
                  <ListItemText disableTypography sx={{ ml: 0, pl: 0 }}>
                    <Typography variant="caption" style={{ fontWeight: "300" }}>
                      {x?.details}
                    </Typography>

                    {x?.title === "terms" && (
                      <Button
                        size="small"
                        onClick={() => {
                          setCurrentLegal(x.title);
                          handleClickOpen();
                        }}
                        sx={{
                          textTransform: "none",
                          alignSelf: "center",
                        }}
                      >
                        Terms and Conditions
                      </Button>
                    )}

                    {x?.title === "privacy-policy" && (
                      <Button
                        size="small"
                        onClick={() => {
                          setCurrentLegal(x.title);
                          handleClickOpen();
                        }}
                        sx={{
                          textTransform: "none",
                          alignSelf: "center",
                        }}
                      >
                        Data Collection and Privacy Policies
                      </Button>
                    )}

                    {x?.title === "pricing" && (
                      <Button
                        size="small"
                        onClick={() => {
                          setCurrentLegal(x.title);
                          handleClickOpen();
                        }}
                        sx={{
                          textTransform: "none",
                          alignSelf: "center",
                        }}
                      >
                        Our Pricing
                      </Button>
                    )}

                    {x?.title === "email" && (
                      <Link
                        color="inherit"
                        underline="none"
                        href="mailto:contact@shareduled.com?subject=Question About Shareduled&body=Hello Team Shareduled,"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "300" }}
                        >
                          contact@shareduled.com
                        </Typography>
                      </Link>
                    )}
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Stack>
      <Box sx={{ width: "inherit", pt: { xs: 2, sm: 3, md: 4 } }}>
        <a href="https://www.digitalocean.com/?refcode=63ca965d9853&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge">
          <img
            src="https://web-platforms.sfo2.cdn.digitaloceanspaces.com/WWW/Badge%202.svg"
            alt="DigitalOcean Referral Badge"
          />
        </a>
      </Box>
    </Container>
  );
}
