import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { ROIDataForStepper } from "./screens/ROICalculatorScreen";
import { MarketingUserDto } from "../sdk/open-api";
import { InputAdornment } from "@mui/material";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator=","
        valueIsNumericString
        decimalScale={0}
      />
    );
  }
);

export default function BookingForm(
  props: ROIDataForStepper & {
    user: MarketingUserDto | null;
  }
) {
  const { averageMonthlyBookings, averageMonthlyNoShows, setData, user } =
    props;

  const handleFocus = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.target.select();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    switch (name) {
      case "averagemonthlybookings":
        setData((prev) => {
          return {
            ...prev,
            averageMonthlyBookings: Number(value.replaceAll(",", "")),
          };
        });

        break;
      case "averagemonthlynoshows":
        setData((prev) => {
          return {
            ...prev,
            averageMonthlyNoShows: Number(value.replaceAll(",", "")),
          };
        });
        break;

      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {`This is the Final Step ${
          user?.first_name ? user?.first_name : ""
        }. We Promise.`}
      </Typography>
      <Typography variant="body2" gutterBottom style={{ fontWeight: "200" }}>
        You've come to see the the Power of Shareduled's Smart Scheduling for
        your business. We just need this little data to finalize things for you.
        <br />
        Ready, set, go!
      </Typography>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            onFocus={handleFocus}
            required
            id="averagemonthlybookings"
            name="averagemonthlybookings"
            label="Average Monthly Bookings"
            fullWidth
            autoComplete="average-monthly-bookings"
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">booking(s)</InputAdornment>
              ),
              inputComponent: NumericFormatCustom as any,
            }}
            helperText="Here, please enter your average monthly bookings and average booking value. This is the measure of how many customers you serve and how much you charge per service. You can find this information from your booking system or reports."
            value={averageMonthlyBookings ?? ""}
            placeholder="12,000"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            onFocus={handleFocus}
            id="averagemonthlynoshows"
            name="averagemonthlynoshows"
            label="Average Monthly No-shows"
            fullWidth
            autoComplete="average-monthly-noshows"
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">booking(s)</InputAdornment>
              ),
              inputComponent: NumericFormatCustom as any,
            }}
            helperText="Here, please enter your average monthly no-shows and average no-show rate. This is the measure of how many customers fail to show up for their appointments and how much revenue you lose as a result. You can find this information from your booking system or reports."
            value={averageMonthlyNoShows ?? ""}
            placeholder="12,000"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
