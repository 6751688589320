import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
} from "@mui/material";
import { AppColours } from "../../helpers/colors";

type ISAData = {
  headerRed1: string;
  headerBlack1: string;
  headerRed2: string;
  headerBlack2: string;
  text: string;
};

const data: ISAData[] = [
  {
    headerRed1: "Real-time ",
    headerBlack1: "Walk-in & Online Booking - Manage both walk-in and ",
    headerRed2: "online bookings live",
    headerBlack2: "",
    text: "Accept walk-in appointments and seamlessly use our online booking system to streamline your workflow.",
  },
  {
    headerRed1: "",
    headerBlack1: "Effortless Online Booking - ",
    headerRed2: "Reduce phone calls ",
    headerBlack2: "and wasted time",
    text: "Allow clients to book appointments 24/7 through a user-friendly online booking system.",
  },
  {
    headerRed1: "",
    headerBlack1: "Automated Reminders & Confirmations - ",
    headerRed2: "Reduce no-shows ",
    headerBlack2: "and improve client communication",
    text: "Set up automated appointment confirmations, reminders, and follow-ups.",
  },
];

const ShareduledAdvantageInternal = (
  props: ISAData & {
    image: any;
  }
) => (
  <Grid
    item
    xs={12}
    sm={4}
    sx={{
      height: "inherit",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Card
      elevation={2}
      raised
      sx={{
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        pr: { xs: 3, sm: 4, md: 4 },
        pl: { xs: 3, sm: 4, md: 4 },
        pt: { xs: 3, sm: 4, md: 5 },
        pb: { xs: 3, sm: 4, md: 5 },
        boxShadow: "0px 2px 2px rgba(156, 0, 188, 1)",
      }}
    >
      <CardMedia
        component="img"
        loading="lazy"
        image={props?.image}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: { xs: 150, sm: 150, md: 240 },
          width: { xs: 150, sm: 150, md: 240 },
          borderRadius: 100,
        }}
      />

      <Typography
        variant="h6"
        sx={{
          lineHeight: "26px",
          textAlign: "center",
          fontWeight: "600",
          color: AppColours.MainRed,
          pt: { xs: 3, sm: 4, md: 5 },
          pb: { xs: 2, sm: 3, md: 4 },
        }}
      >
        {props?.headerRed1}

        <div>
          <Typography
            variant="h6"
            sx={{
              lineHeight: "26px",
              textAlign: "center",
              fontWeight: "500",
              color: AppColours.MainBlack,
            }}
          >
            {props?.headerBlack1}
          </Typography>
        </div>

        {props?.headerRed2}

        <div>
          <Typography
            variant="h6"
            sx={{
              lineHeight: "26px",
              textAlign: "center",
              fontWeight: "500",
              color: AppColours.MainBlack,
            }}
          >
            {props?.headerBlack2}
          </Typography>
        </div>
      </Typography>

      <Typography
        variant="body2"
        sx={{
          textAlign: "center",
          fontWeight: "300",
        }}
      >
        {props?.text}
      </Typography>
    </Card>
  </Grid>
);

export default function ShareduledAdvantage() {
  return (
    <Container
      maxWidth="lg"
      id="shareduled-advantage-section"
      sx={{
        minHeight: "inherit",
        justifyContent: "center",
        alignItems: "center",
        pt: { xs: 4, sm: 6 },
        pb: { xs: 8, sm: 10 },
      }}
    >
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: (theme) => {
            return {
              [theme.breakpoints.down("sm")]: {
                display: "flex-start",
              },
            };
          },
          pb: { xs: 6, sm: 8 },
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: "600",
            color: (theme) => theme?.palette?.primary?.main,
            display: "flex",
            alignSelf: (theme) => {
              return {
                [theme.breakpoints.down("sm")]: {
                  display: "flex-start",
                },
              };
            },
            flexWrap: "wrap",
            textAlign: "left",
          }}
        >
          Experience the Shareduled Advantage
        </Typography>
      </Box>
      <Grid
        container
        spacing={{ xs: 5, sm: 6, md: 7 }}
        sx={{
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "inherit",
        }}
      >
        {data.map((x, index) => {
          let imageData =
            "https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/real-time-icon.png";

          switch (index) {
            case 1:
              imageData =
                "https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/booked-icon.png";
              break;
            case 2:
              imageData =
                "https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/time-icon.png";
              break;

            default:
              imageData =
                "https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/real-time-icon.png";
              break;
          }

          return (
            <ShareduledAdvantageInternal
              key={index.toString()}
              {...x}
              image={imageData}
            />
          );
        })}
      </Grid>
    </Container>
  );
}
