import countries from "i18n-iso-countries";
import englishLocale from "i18n-iso-countries/langs/en.json";

countries.registerLocale(englishLocale);

// ROI = [(Projected Profit Margin - Current Profit Margin)/Application Cost] * 100%

// Application Cost = Monthly subscription fee or Percentage of Revenue (lifetime subscription)

/**
 * Projected Profit Margin = [(Projected Revenue - Projected Expenses)/Projected Revenue] * 100%
 *
 * Current Profit Margin = [(Current Revenue - Current Expenses)/Current Revenue] * 100%
 */
export function profitMarginCalculator(revenue: number, expenses: number) {
  return ((revenue - expenses) / revenue) * 100;
}

/**
 * ROI = [(Projected Profit Margin - Current Profit Margin)/Application Cost] * 100%
 *
 * Application Cost = Monthly subscription fee or Percentage of Revenue (lifetime subscription)
 */
export function returnOnInvestment(
  projectedProfitMargin: number,
  currentProfitMargin: number,
  applicationCost: number
) {
  return (
    ((projectedProfitMargin - currentProfitMargin) / applicationCost) * 100
  );
}

export function projectedCashflow(currentData: {
  revenue: number;
  expenses: number;
  monthlyMarketingSpend: number;
  monthlyBookings: number;
  monthlyNoShows: number;
}) {
  // Add logic to calculate these data from projection calculations
  return {
    revenue: currentData.revenue * 6.9,
    expenses: currentData.expenses * 1.1,
    monthlyMarketingSpend: currentData.monthlyMarketingSpend * 0.88,
    monthlyBookings: currentData.monthlyBookings * 5.2,
    monthlyNoShows: currentData.monthlyNoShows * 0.25,
  };
}

const allowedPhoneNumberChars = "0123456789+";

export const formatPhoneNumber = (rawTelNo: string) => {
  let telNo = "";

  for (const char of rawTelNo) {
    if (allowedPhoneNumberChars.includes(char)) telNo += char;
  }

  return telNo;
};

const officialCountries = countries.getNames("en", { select: "official" });
export const officialCountriesArray = Object.entries(officialCountries)
  .map(([key, value]) => {
    return {
      shortCode: key.toLowerCase(),
      name: value,
    };
  })
  .sort((a, b) => {
    const A = a.name.toLowerCase();
    const B = b.name.toLowerCase();

    return A < B ? -1 : A > B ? 1 : 0;
  });

export const APPLICATION_COST = 75;
