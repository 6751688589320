import React, { useEffect, useState } from "react";
import LayoutV2 from "../layout/LayoutV2";
import PaymentSuccessHome from "../lead-magnet/PaymentSuccessHome";
import { MarketingUserDto } from "../../sdk/open-api";

export default function PaymentSuccessScreen() {
  const [user, setUser] = useState<{
    user: MarketingUserDto | null;
    existing: boolean;
  }>({ user: null, existing: false });

  useEffect(() => {
    function getUser() {
      const currentUser = localStorage.getItem("user_data");
      if (!currentUser) return;

      setUser(
        JSON.parse(currentUser) as unknown as {
          user: MarketingUserDto | null;
          existing: boolean;
        }
      );
    }

    getUser();
  }, []);

  return (
    <LayoutV2
      hideNavLinks
      hideFooterNav
      hideFooterResources
      child={
        <React.Fragment>
          <PaymentSuccessHome user={user?.user} />
        </React.Fragment>
      }
    />
  );
}
