import {
  Box,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

type IData = {
  header: string;
  text1: string;
  text2: string;
  boldText: string;
};

const data: IData[] = [
  {
    header: "Solving the Scheduling Puzzle for You",
    text1:
      "The scheduling puzzle can be complex, with too many pieces and not enough clarity.",
    text2:
      "Clients can't find slots, and you can't find peace. It's a lose-lose situation.",
    boldText:
      "We provide the missing piece, bringing clarity and control back to your calendar.",
  },
  {
    header: "Transforming Appointment Chaos into Calm",
    text1:
      "The struggle is real: juggling multiple appointments can feel like a never-ending battle.",
    text2:
      "Missed bookings, double-bookings, and last-minute cancellations disrupt not just your day but your entire business flow.",
    boldText:
      "Imagine a world where every appointment slots into place perfectly, like a serene symphony of scheduling.",
  },
  {
    header: "From Scheduling Stress to Success",
    text1: "Scheduling should be a breeze, not a headache.",
    text2:
      "The constant back-and-forth, the confusion, the clients left waiting—all these are the enemies of a thriving business.",
    boldText:
      "Step into a new era where success is scheduled with precision, and stress is left at the door.",
  },
  {
    header: "Eradicating Booking Blues, One Click at a Time",
    text1:
      "The booking blues hit hard when appointments clash and clients clash harder.",
    text2:
      "It's the chaos of overbooked days and the frustration of underutilized slots.",
    boldText:
      "With a click, transform your booking experience into a harmonious flow that keeps clients coming back.",
  },
];

const SolvingPuzzleInternal = (
  props: IData & {
    index: number;
    image: any;
  }
) => (
  <Grid
    container
    direction={props?.index % 2 ? "row" : "row-reverse"}
    spacing={{ xs: 8, sm: 8 }}
    sx={{
      justifyContent: "center",
      alignItems: "center",
      minHeight: "inherit",
    }}
  >
    <Grid
      item
      xs={12}
      sm={6}
      sx={{
        height: "inherit",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        spacing={{ xs: 2, sm: 3 }}
        useFlexGap
        sx={{
          height: "inherit",
          width: { xs: "100%", sm: "100%" },
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          pt: { xs: 10, sm: 20 },
        }}
      >
        <Typography
          variant="h4"
          align="left"
          sx={{
            fontWeight: "500",
          }}
        >
          {props?.header}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            alignSelf: "flex-start",
            flexWrap: "wrap",
            textAlign: "left",
            fontWeight: "300",
          }}
        >
          {props?.text1}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            alignSelf: "flex-start",
            flexWrap: "wrap",
            textAlign: "left",
            fontWeight: "300",
          }}
        >
          {props?.text2}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            alignSelf: "flex-start",
            flexWrap: "wrap",
            textAlign: "left",
            fontWeight: "500",
          }}
        >
          {props?.boldText}
        </Typography>
      </Stack>
    </Grid>
    <Grid
      item
      xs={12}
      sm={6}
      sx={{
        height: "inherit",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          maxHeight: "100vh",
          pt: { xs: 12, sm: 14 },
        }}
      >
        <CardMedia
          component="img"
          loading="lazy"
          src="image"
          image={props?.image}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: { xs: 360, sm: 660 },
            borderRadius: 5,
          }}
        />
      </Box>
    </Grid>
  </Grid>
);

export default function SolvingPuzzle() {
  return (
    <Container
      maxWidth="lg"
      id="solving-puzzle-section"
      sx={{
        minHeight: "inherit",
        justifyContent: "center",
        alignItems: "center",
        pt: { xs: 4, sm: 6 },
        pb: { xs: 8, sm: 10 },
      }}
    >
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: (theme) => {
            return {
              [theme.breakpoints.down("sm")]: {
                display: "flex-start",
              },
            };
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            color: (theme) => theme?.palette?.primary?.main,
            display: "flex",
            alignSelf: (theme) => {
              return {
                [theme.breakpoints.down("sm")]: {
                  display: "flex-start",
                },
              };
            },
            flexWrap: "wrap",
            textAlign: "left",
          }}
        >
          Solving The Scheduling Puzzle and Booking Bottlenecks
        </Typography>
      </Box>

      {data.map((x, index) => {
        let imageData =
          "https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/hero-01-solving-scheduling-puzzle.png";

        switch (index) {
          case 1:
            imageData =
              "https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/hero-02-transform-appt-chaos-to-calm.png";
            break;
          case 2:
            imageData =
              "https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/hero-03-from-scheduling-stress-to-success.png";
            break;
          case 3:
            imageData =
              "https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/hero-04-eradicating-booking-blues.png";
            break;

          default:
            imageData =
              "https://shareduled-assets.nyc3.cdn.digitaloceanspaces.com/marketing/hero-01-solving-scheduling-puzzle.png";
            break;
        }

        return (
          <SolvingPuzzleInternal
            key={index.toString()}
            {...x}
            index={index}
            image={imageData}
          />
        );
      })}
    </Container>
  );
}
