import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function TermsAndConditions() {
  return (
    <Stack spacing={{ xs: 2, sm: 3 }} useFlexGap>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        1. Agreement
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        These Terms and Conditions ("Terms") govern your use of our website and
        services (collectively, the "Service") provided by Shareduled. By
        accessing or using the Service, you agree to be bound by these Terms. If
        you disagree with any part of these Terms, you may not access or use the
        Service.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        2. Use of the Service
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        You agree to use the Service in accordance with all applicable laws and
        regulations. You will not use the Service for any illegal or
        unauthorized purpose, nor will you infringe on the intellectual property
        rights of others. We reserve the right to terminate your access to the
        Service at any time for any reason, with or without notice.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        3. Disclaimers
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        The Service is provided "as is" and without warranties of any kind,
        express or implied. We disclaim all warranties, including, but not
        limited to, warranties of merchantability, fitness for a particular
        purpose, and non-infringement. We do not warrant that the Service will
        be uninterrupted, error-free, or secure.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        4. Limitation of Liability
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        In no event shall we be liable for any direct, indirect, incidental,
        consequential, or punitive damages arising out of or related to your use
        of the Service. This includes, but is not limited to, damages for loss
        of profits, data loss, or business interruption.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        5. Governing Law and Dispute Resolution
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        These Terms shall be governed by and construed in accordance with the
        laws of the Province of Ontario, without regard to its conflict of law
        provisions. Any dispute arising out of or relating to these Terms shall
        be subject to the exclusive jurisdiction of the courts located in
        Toronto, Ontario, Canada.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        6. User Responsibilities
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        As a user of the Service, you agree to:
      </Typography>

      <List sx={{ pl: { xs: 3, sm: 4, md: 5 } }}>
        {[
          "Provide accurate and up-to-date information during the registration process and while using the Service.",
          "Maintain the confidentiality of your account credentials and promptly notify us of any unauthorized use of your account.",
          "Use the Service in a manner that does not disrupt, overburden, or harm the Service or its infrastructure.",
        ].map((x, index) => (
          <ListItem disablePadding key={index.toString()}>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography variant="body2" style={{ fontWeight: "300" }}>
                {x}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        7. Intellectual Property
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        All content, trademarks, service marks, logos, and other intellectual
        property displayed on the Service are the property of Shareduled or its
        licensors. You may not use, reproduce, distribute, or create derivative
        works of such materials without our prior written consent.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        8. Modifications to the Service and Terms
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We reserve the right to modify or discontinue the Service at any time,
        with or without notice to you. We may also update these Terms from time
        to time. Your continued use of the Service after any such changes
        constitutes your acceptance of the new Terms. We encourage you to review
        these Terms periodically for any updates.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        9. Termination
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        We may terminate or suspend your access to the Service immediately,
        without prior notice or liability, for any reason whatsoever, including
        without limitation if you breach the Terms. Upon termination, your right
        to use the Service will immediately cease.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        10. Severability
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontWeight: "300",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        If any provision of these Terms is found to be unenforceable or invalid,
        the remaining provisions will continue to be in effect. These Terms
        constitute the entire agreement between us regarding our Service, and
        supersede and replace any prior agreements we might have between us
        regarding the Service.
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          display: "flex",
          flexWrap: "wrap",
          textAlign: "left",
        }}
      >
        11. Contact Information
      </Typography>

      <Typography
        variant="body1"
        sx={{ fontWeight: "300", mb: { xs: 4, sm: 5, md: 6 } }}
      >
        If you have any questions about these Terms, please contact us at&nbsp;
        <Link
          color="inherit"
          href="mailto:support@shareduled.com?subject=About Shareduled Terms and Conditions&body=Hello Team Shareduled,"
          target="_blank"
          rel="noopener noreferrer"
        >
          support@shareduled.com
        </Link>
      </Typography>
    </Stack>
  );
}
