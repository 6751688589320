import { useCallback } from "react";
import { ShareduledUser } from "../components/screens/LeadMagnetScreen";
import {
  validateMarketingUserEmail,
  validateMarketingUserPhoneNumber,
} from "../services/apis/user.service";

export const useMemoizedVerifyUserEmail = (
  email: string,
  setUser: React.Dispatch<React.SetStateAction<ShareduledUser>>
) => {
  const verifyUserEmail = useCallback(async () => {
    const env = process.env.REACT_APP_NODE_ENV;

    if (env !== "production") {
      setUser((prev) => {
        return {
          ...prev,
          businessEmailValid: true,
        };
      });

      return;
    }

    const cached = localStorage.getItem(email);

    if (cached) {
      const parsedCache = JSON.parse(cached) as unknown as {
        isValid: boolean;
        hasServerError: boolean;
      };

      if (parsedCache.hasServerError) {
        // Call API again to verify email
        const isValidEmail = await validateMarketingUserEmail({
          email,
        });


        localStorage.setItem(
          email,
          JSON.stringify({
            isValid: isValidEmail.isValid,
            hasServerError: isValidEmail.hasServerError,
          })
        );

        setUser((prev) => {
          return {
            ...prev,
            businessEmailValid: isValidEmail.isValid,
          };
        });
      } else {
        setUser((prev) => {
          return {
            ...prev,
            businessEmailValid: parsedCache.isValid,
          };
        });
      }
    } else {
      // Call API to verify email
      const isValidEmail = await validateMarketingUserEmail({
        email,
      });


      localStorage.setItem(
        email,
        JSON.stringify({
          isValid: isValidEmail.isValid,
          hasServerError: isValidEmail.hasServerError,
        })
      );

      setUser((prev) => {
        return {
          ...prev,
          businessEmailValid: isValidEmail.isValid,
        };
      });
    }
  }, [email, setUser]);

  return verifyUserEmail;
};

export const useMemoizedVerifyPhoneNumber = (
  phoneNumber: string,
  setUser: React.Dispatch<React.SetStateAction<ShareduledUser>>
) => {
  const verifyUserPhoneNumber = useCallback(async () => {
    const env = process.env.REACT_APP_NODE_ENV;

    if (env !== "production") {
      setUser((prev) => {
        return {
          ...prev,
          phoneNumberValid: true,
        };
      });
      return;
    }
    const cached = localStorage.getItem(phoneNumber);

    if (cached) {
      const parsedCache = JSON.parse(cached) as unknown as {
        isValid: boolean;
        hasServerError: boolean;
      };

      if (parsedCache.hasServerError) {
        // Call API again to verify phone
        const isValidTelNo = await validateMarketingUserPhoneNumber({
          phoneNumber,
        });


        localStorage.setItem(
          phoneNumber,
          JSON.stringify({
            isValid: isValidTelNo.isValid,
            hasServerError: isValidTelNo.hasServerError,
          })
        );

        setUser((prev) => {
          return {
            ...prev,
            phoneNumberValid: isValidTelNo.isValid,
          };
        });
      } else {
        setUser((prev) => {
          return {
            ...prev,
            phoneNumberValid: parsedCache.isValid,
          };
        });
      }
    } else {
      // Call API to verify phone
      const isValidTelNo = await validateMarketingUserPhoneNumber({
        phoneNumber,
      });


      localStorage.setItem(
        phoneNumber,
        JSON.stringify({
          isValid: isValidTelNo.isValid,
          hasServerError: isValidTelNo.hasServerError,
        })
      );

      setUser((prev) => {
        return {
          ...prev,
          phoneNumberValid: isValidTelNo.isValid,
        };
      });
    }
  }, [phoneNumber, setUser]);

  return verifyUserPhoneNumber;
};
