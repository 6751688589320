import { useEffect, useState } from "react";
import { MarketingUserDto } from "../../sdk/open-api";
import LayoutV2 from "../layout/LayoutV2";
import OurGiftComponent from "../lead-magnet/OurGiftComp";
import { useLocation, useSearchParams } from "react-router-dom";
import { fetchWaitlistUser } from "../../services/apis/user.service";

export default function OurGiftScreen() {
  let location = useLocation();
  let [urlQueryParameters] = useSearchParams();

  const [currentUser, setCurrentUser] = useState<MarketingUserDto | null>(null);
  const [existing, setExisting] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [disablePaymentButton, setDisablePaymentButton] = useState(false);

  useEffect(() => {
    async function setUser() {
      const userUuid = urlQueryParameters.get("identifier");

      if (userUuid) {
        // Set the user to the user with corresponding uuid
        try {
          const user = await fetchWaitlistUser({ uuid: userUuid });

          if (!user) throw new Error("Could not find user");
          if (!user.data) throw user;

          setCurrentUser(user.data as unknown as MarketingUserDto);
          setDisablePaymentButton(
            (user.data as any).payment_status === "success"
          );
        } catch (e: any) {
          console.warn("SGU Error: ", e);
          setErrMsg(
            typeof e === "string" ? e : e.message ?? "Something went wrong"
          );
          setDisablePaymentButton(true);
        }
      } else {
        // This is a continuing flow. Use cached user
        const locationData = location.state as {
          user: MarketingUserDto | null;
          existing: boolean;
        };

        if (locationData?.user) {
          setCurrentUser(locationData.user as unknown as MarketingUserDto);
          setExisting(locationData?.existing ?? false);
          setDisablePaymentButton(
            (locationData.user as any).payment_status === "success"
          );
        } else {
          setErrMsg("No user found");
          setDisablePaymentButton(true);
        }
      }
    }

    setUser();
  }, [location, urlQueryParameters]);

  return (
    <LayoutV2
      hideNavLinks
      hideFooterNav
      hideFooterResources
      child={
        <OurGiftComponent
          user={currentUser}
          existing={existing}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          disablePayBtn={disablePaymentButton}
        />
      }
    />
  );
}
