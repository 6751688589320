import { Box, Container, Grid, Card, Typography } from "@mui/material";
import { ReactNode } from "react";
import { AppColours } from "../../helpers/colors";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import TuneIcon from "@mui/icons-material/Tune";
import DateRangeIcon from "@mui/icons-material/DateRange";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import TimerIcon from "@mui/icons-material/Timer";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import GoogleIcon from "@mui/icons-material/Google";
import PaidIcon from "@mui/icons-material/Paid";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";

type IBFData = {
  header: string;
  subHeader: string;
  text1: string;
  text2: string;
};

const data: IBFData[] = [
  {
    header: "Real-Time Booking System",
    subHeader: "Why Care?",
    text1:
      "Eliminate the guesswork and missed opportunities with our real-time booking system.",
    text2:
      "Ensure your clients can book their appointments when it's most convenient for them, leading to increased satisfaction and repeat business.",
  },
  {
    header: "Live Availability Notifications",
    subHeader: "Why Care?",
    text1: "Keep your clients informed and engaged with live updates.",
    text2:
      "This proactive communication can reduce no-shows and last-minute cancellations, optimizing your schedule and revenue.",
  },
  {
    header: "Custom Branding Options",
    subHeader: "Why Care?",
    text1: "Create a seamless brand experience with our customization options.",
    text2:
      "Consistent branding across your booking platform reinforces your professional image and can increase client trust and loyalty.",
  },
  {
    header: "Integrated Walk-In and Online Booking",
    subHeader: "Why Care?",
    text1:
      "Cater to all client preferences by supporting both walk-ins and online bookings.",
    text2:
      "This flexibility can expand your client base and fill your schedule more effectively.",
  },
  {
    header: "Intelligent Location Recommendations",
    subHeader: "Why Care?",
    text1: "Attract clients by making it easy for them to find you.",
    text2:
      "Our location-based recommendations can drive traffic to your business and increase visibility in a competitive market.",
  },
  {
    header: "24/7 Booking Accessibility",
    subHeader: "Why Care?",
    text1: "Never miss a booking opportunity with 24/7 accessibility.",
    text2:
      "This convenience can significantly enhance client satisfaction and set you apart from competitors who don't offer round-the-clock booking.",
  },
  {
    header: "Seamless Cancellation and Rescheduling",
    subHeader: "Why Care?",
    text1: "Handle changes with ease, minimizing disruption to your business.",
    text2:
      "Our seamless process for cancellations and reschedules keeps your operations smooth and clients happy.",
  },
  {
    header: "Google My Business Integration",
    subHeader: "Why Care?",
    text1: "Leverage the power of Google for greater reach.",
    text2:
      "Integrating with Google My Business can improve your online presence and attract more clients through search visibility.",
  },
  {
    header: "Automated Payment Processing",
    subHeader: "Why Care?",
    text1:
      "Simplify the payment process, reduce manual work, and get paid faster with secure online payments.",
    text2: "",
  },
  {
    header: "Automated Confirmations & Reminders",
    subHeader: "Why Care?",
    text1:
      "Reduce no-shows, improve client communication, and free up staff time from manual confirmations.",
    text2: "",
  },
];

const BusinessFeaturesInternal = (props: IBFData & { icon: ReactNode }) => (
  <Grid
    item
    xs={12}
    sm={6}
    sx={{
      height: "inherit",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Card
      elevation={0}
      sx={{
        borderRadius: 5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "flex-start",
        pr: { xs: 3, sm: 4, md: 4 },
        pl: { xs: 3, sm: 4, md: 4 },
      }}
    >
      {props?.icon}
      <Box sx={{ pl: { xs: 2, sm: 3, md: 4 } }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            lineHeight: "26px",
            textAlign: "left",
            fontWeight: "600",
            color: AppColours.MainBlack,
          }}
        >
          {props?.header}
        </Typography>

        <Typography
          variant="body1"
          gutterBottom
          sx={{
            lineHeight: "26px",
            textAlign: "left",
            fontWeight: "600",
            color: AppColours.MainRed,
            fontStyle: "italic",
          }}
        >
          {props?.subHeader}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            lineHeight: "26px",
            textAlign: "left",
            fontWeight: "300",
            color: AppColours.MainBlack,
            pb: { xs: 1, sm: 2, md: 3 },
          }}
        >
          {props?.text1}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            lineHeight: "26px",
            textAlign: "left",
            fontWeight: "300",
            color: AppColours.MainBlack,
            pb: { xs: 2, sm: 3, md: 4 },
          }}
        >
          {props?.text2}
        </Typography>
      </Box>
    </Card>
  </Grid>
);

export default function BusinessFeatures() {
  return (
    <Container
      maxWidth="lg"
      id="business-features-section"
      sx={{
        minHeight: "inherit",
        justifyContent: "center",
        alignItems: "center",
        pt: { xs: 4, sm: 6 },
        pb: { xs: 8, sm: 10 },
      }}
    >
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: (theme) => {
            return {
              [theme.breakpoints.down("sm")]: {
                display: "flex-start",
              },
            };
          },
          pb: { xs: 6, sm: 8 },
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: "600",
            color: (theme) => theme?.palette?.primary?.main,
            display: "flex",
            alignSelf: (theme) => {
              return {
                [theme.breakpoints.down("sm")]: {
                  display: "flex-start",
                },
              };
            },
            flexWrap: "wrap",
            textAlign: "left",
          }}
        >
          Features That Fuel Your Business
        </Typography>
      </Box>

      <Grid
        container
        spacing={{ xs: 5, sm: 6, md: 7 }}
        sx={{
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "inherit",
        }}
      >
        {data.map((x, index) => {
          let componentIcon = (
            <MyLocationIcon fontSize="large" color="primary" />
          );

          switch (index) {
            case 1:
              componentIcon = (
                <NotificationsActiveIcon fontSize="large" color="primary" />
              );
              break;
            case 2:
              componentIcon = <TuneIcon fontSize="large" color="primary" />;
              break;
            case 3:
              componentIcon = (
                <DateRangeIcon fontSize="large" color="primary" />
              );
              break;
            case 4:
              componentIcon = (
                <LocationSearchingIcon fontSize="large" color="primary" />
              );
              break;
            case 5:
              componentIcon = <TimerIcon fontSize="large" color="primary" />;
              break;
            case 6:
              componentIcon = (
                <PublishedWithChangesIcon fontSize="large" color="primary" />
              );
              break;
            case 7:
              componentIcon = <GoogleIcon fontSize="large" color="primary" />;
              break;
            case 8:
              componentIcon = <PaidIcon fontSize="large" color="primary" />;
              break;
            case 9:
              componentIcon = (
                <CircleNotificationsIcon fontSize="large" color="primary" />
              );
              break;

            default:
              break;
          }

          return (
            <BusinessFeaturesInternal
              key={index.toString()}
              {...x}
              icon={componentIcon}
            />
          );
        })}
      </Grid>
    </Container>
  );
}
