import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AppColours } from "../../helpers/colors";
import { useNavigate } from "react-router-dom";

type MarketingModalType = {
  open: boolean;
  handleClose: () => void;
};

type MarketingModalInternalType = {
  open: boolean;
  handleClose: () => void;
  headline: string;
  subText: string;
  buttonText: string;
  cancelButtonText: string;
  timerData: string;
};

function MarketingModalInternal(props: MarketingModalInternalType) {
  const navigate = useNavigate();

  const {
    open,
    handleClose,
    headline,
    subText,
    buttonText,
    cancelButtonText,
    timerData,
  } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            color="primary"
            sx={{
              fontWeight: "500",
              pt: { xs: 2, sm: 1, md: 1 },
            }}
          >
            {headline}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack
            spacing={{ xs: 4, sm: 3, md: 2 }}
            useFlexGap
            sx={{
              height: "inherit",
              width: { xs: "100%", sm: "100%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              pt: { xs: 2, sm: 1, md: 1 },
              pb: { xs: 1, sm: 0, md: 0 },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
              }}
            >
              Act Fast - Secure Your Seat as you enjoy&nbsp;
              <Typography
                component="span"
                variant="body1"
                sx={{
                  fontWeight: "600",
                }}
              >
                flexible bookings
              </Typography>
              ,{" "}
              <Typography
                component="span"
                variant="body1"
                sx={{
                  fontWeight: "600",
                }}
              >
                real-time updates
              </Typography>
              ,{" "}
              <Typography
                component="span"
                variant="body1"
                sx={{
                  fontWeight: "600",
                }}
              >
                live availability changes
              </Typography>
              , and{" "}
              <Typography
                component="span"
                variant="body1"
                sx={{
                  fontWeight: "600",
                }}
              >
                effortless payment management&nbsp;
              </Typography>
              for a{" "}
              <Typography
                component="span"
                variant="body1"
                sx={{
                  fontWeight: "600",
                  color: AppColours.MainRed,
                }}
              >
                One-Time 500% Savings Today
              </Typography>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "300",
                fontStyle: "italic",
              }}
            >
              {subText}
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              sx={{
                alignSelf: "center",
                fontWeight: "500",
                color: AppColours.MainRed,
              }}
            >
              {timerData}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            spacing={{ xs: 2, sm: 2, md: 1 }}
            useFlexGap
            sx={{
              height: "inherit",
              width: { xs: "100%", sm: "100%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: 1, sm: 0, md: 0 },
              pb: { xs: 3, sm: 2, md: 2 },
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate("/life-time-access-form");
              }}
              sx={{
                width: { xs: "100%", sm: "90%", md: "80%" },
                textTransform: "none",
                backgroundColor: AppColours.MainGreen,
                padding: "12px",
                borderRadius: { xs: 10, sm: 12 },
              }}
            >
              {buttonText}
            </Button>
            <Button
              variant="text"
              onClick={handleClose}
              sx={{
                width: { xs: "100%", sm: "90%", md: "80%" },
                textTransform: "none",
                textDecoration: "underline",
                color: AppColours.MainRed,
              }}
            >
              {cancelButtonText}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}

function MarketingModal(props: MarketingModalType) {
  const { open, handleClose } = props;

  const [timerMins, setTimerMins] = useState(5);
  const [timerSecs, setTimerSecs] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (open) {
        if (timerMins === 0 && timerSecs === 0) {
          handleClose();
        }

        if (timerSecs === 0 && timerMins > 0) {
          setTimerSecs((_) => 59);
          setTimerMins((prev) => prev - 1);
        } else if (timerMins >= 0 && timerSecs !== 0) {
          setTimerSecs((prev) => prev - 1);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timerMins, timerSecs, handleClose, open]);

  return (
    <Container
      maxWidth="md"
      id="marketing-modal-section"
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MarketingModalInternal
        open={open}
        handleClose={handleClose}
        headline={"Limited-Time Lifetime Offer"}
        subText={
          "*If you use it for a year and decide it's not for you, we'll give you a full refund and even throw in an extra month for free"
        }
        buttonText={"Give Me The LifeTime Access Now"}
        cancelButtonText={"I don’t mind missing out on this offer today"}
        timerData={`${timerMins.toString().padStart(2, "0")}:${timerSecs
          .toString()
          .padStart(2, "0")}`}
      />
    </Container>
  );
}

export default MarketingModal;
