import {
  AddOneRoiCalcResultDto,
  FetchWaitlistUserDto,
  MarketingUserDto,
  MarketingUserOneRespDto,
  RoiCalcRespDto,
  VerifyEmailInputDto,
  VerifyPhoneInputDto,
} from "../../sdk/open-api";
import { publicApiClient } from "./main";

export const createMarketingUser = async (data: MarketingUserDto) => {
  try {
    const resp = (
      await publicApiClient.publicControllerCreateMarketingUser(data)
    )?.data;

    if (!resp)
      throw new Error(`No response for creating user ${data.business_email}`);
    return resp;
  } catch (e: any) {
    console.warn("CMU Api Error: ", JSON.stringify(e, null, 2));
    const {
      message,
      code,
      response: { data },
    } = e;
    return {
      data: null,
      message:
        data.message && typeof data.message === "string"
          ? data.message
          : data.message && Array.isArray(data.message)
          ? data.message[0]
          : message
          ? message
          : code
          ? code
          : "Server returned error",
    };
  }
};

export const validateMarketingUserEmail = async (data: VerifyEmailInputDto) => {
  try {
    const resp = (await publicApiClient.publicControllerValidateEmail(data))
      ?.data;

    if (!resp) throw new Error(`No response for validating ${data.email}`);

    return resp;
  } catch (e) {
    console.warn("VMUE Api Error: ", JSON.stringify(e, null, 2));

    return {
      isValid: false,
      hasServerError: true,
    };
  }
};

export const validateMarketingUserPhoneNumber = async (
  data: VerifyPhoneInputDto
) => {
  try {
    const resp = (
      await publicApiClient.publicControllerValidatePhoneNumber(data)
    )?.data;

    if (!resp)
      throw new Error(`No response for validating ${data.phoneNumber}`);

    return resp;
  } catch (e) {
    console.warn("VMUPN Api Error: ", JSON.stringify(e, null, 2));

    return {
      isValid: false,
      hasServerError: true,
    };
  }
};

export const saveRoiCalculatorResults = async (
  data: AddOneRoiCalcResultDto
): Promise<RoiCalcRespDto> => {
  try {
    const resp = (
      await publicApiClient.publicControllerSaveRoiCalculatorResult(data)
    )?.data;

    if (!resp)
      throw new Error(
        `No response while saving data for ${data.marketing_user_uuid}`
      );

    return resp;
  } catch (e: any) {
    const {
      message,
      code,
      response: { data },
    } = e;

    return {
      data: null,
      success: false,
      message:
        data.message && typeof data.message === "string"
          ? data.message
          : data.message && Array.isArray(data.message)
          ? data.message[0]
          : message
          ? message
          : code
          ? code
          : "Server returned error",
    };
  }
};

export const fetchWaitlistUser = async (
  input: FetchWaitlistUserDto
): Promise<MarketingUserOneRespDto> => {
  try {
    const resp = (
      await publicApiClient.publicControllerGetOneWaitlistUser(input)
    )?.data;

    if (!resp)
      throw new Error(`No response while fetching data for ${input.uuid}`);

    return resp;
  } catch (e: any) {
    const {
      message,
      code,
      response: { data },
    } = e;

    return {
      data: null,
      success: false,
      message:
        data.message && typeof data.message === "string"
          ? data.message
          : data.message && Array.isArray(data.message)
          ? data.message[0]
          : message
          ? message
          : code
          ? code
          : "Server returned error",
    };
  }
};
